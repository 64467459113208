import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';

export const ListadoEstadosOperaciones = ({ history, ...props }) => {

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [estadosOperaciones, setEstadosOperaciones] = useState([]);

  const getEstadosOperaciones = async () => {
    try {
      const estadosOperacionesDb = await clienteAxios.get("/estadosOperaciones", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEstadosOperaciones(estadosOperacionesDb.data);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    getEstadosOperaciones();
  }, []);

  console.log(estadosOperaciones)

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-8">
              <h3 className="card-title">Estados de operaciones</h3>
            </div>
            <div className="col-md-4">
              <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Estado</th>
                    <th>Activo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {estadosOperaciones.map(estado => (
                    <tr key={estado._id}>
                      <td>{estado.id}</td>
                      <td>{estado.estado}</td>
                      <td>{estado.activo === 'true' ? 'Sí' : 'No'}</td>
                      <td>
                        <button className="btn btn-primary"
                          onClick={() => history.push(`/estadosOperaciones/editar/${estado._id}`)}
                        >Editar</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </>
  )
}
