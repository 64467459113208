import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import moment from "moment";
import { FormularioCliente } from "./components/FormularioCliente";
import Swal from "sweetalert2";

const EditarCliente = (props) => {
  const [cliente, guardarCliente] = useState({});
  const [operaciones, setOperaciones] = useState([]);
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  // Function to order arrays from smallest to largest:
  const ordenarArray = (a, b) => {
    if (a.nombre > b.nombre) {
      return 1;
    }
    if (a.nombre < b.nombre) {
      return -1;
    }
    return 0;
  };

  // Get the client from the API
  useEffect(() => {
    const consultarCliente = async () => {
      const { id } = props.match.params;
      try {
        const clienteDB = await clienteAxios.get(`/clientes/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        guardarCliente(clienteDB2clientePlano(clienteDB.data));
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    consultarCliente();
    findOperacionesByClientId();
  }, []);

  // Update client data
  const actualizarState = (e) => {
    guardarCliente({
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };

  const validarCliente = () => {
    return !(
      cliente.estadoCivil &&
      cliente.provincia &&
      cliente.municipio &&
      cliente.nacionalidad &&
      cliente.tipoDni
    );
  };

  // Function to convert the client obtained from the API into a flat object
  const clienteDB2clientePlano = (cliente) => {
    let rta = {
      _id: cliente._id,
      cuitCuil: cliente?.documentos?.cuitCuil,
      nombre: cliente.nombre.nombre,
      cuilConyuge: cliente.datosConyuge?.cuil,
      segundoNombre: cliente.nombre.segundoNombre,
      apellido: cliente.nombre.apellido,
      apellidoMaterno: cliente.nombre.apellidoMaterno,
      dni: cliente.documentos.dni,
      tipoDni: cliente.documentos.dniTipo,
      numeroCuilCuit: cliente.documentos.cuitCuil,
      provincia: cliente.domicilio.provincia,
      municipio: cliente.domicilio.municipio,
      calle: cliente.domicilio.calle,
      altura: cliente.domicilio.altura,
      pisoDepto: cliente.domicilio.pisoDepto,
      fechaNacimiento: cliente.fechaNacimiento,
      telefonoMovil: cliente.telefono?.movil,
      telefonoFijo: cliente.telefono?.particular,
      nacionalidad: cliente.nacionalidad,
      email: cliente.email,
      estadoCivil: cliente.estadoCivil,
      nupcias: cliente.nupcias,
      nombreConyuge: cliente.datosConyuge?.nombre,
      segundoNombreConyuge: cliente.datosConyuge?.segundoNombre,
      apellidoConyuge: cliente.datosConyuge?.apellido,
      apellidoMaternoConyuge: cliente.datosConyuge?.apellidoMaterno,
      sexo: cliente.sexo,
      cp: cliente.domicilio.cp,
      activo: cliente.activo,
      inmobiliaria: cliente.inmobiliaria,
      razonSocial: cliente.razonSocial,
      createdFromAfip: cliente.createdFromAfip,
      otherAfipInfo: cliente.otherAfipInfo,
    };
    return rta;
  };

  // Send client to the API
  const agregarCliente = async (e) => {
    e.preventDefault();

    const datosEnviar = {
      _id: cliente._id,
      nombre: {
        nombre: cliente.nombre,
        segundoNombre: cliente.segundoNombre,
        apellido: cliente.apellido,
        apellidoMaterno: cliente.apellidoMaterno,
      },
      documentos: {
        dni: cliente.dni,
        dniTipo: cliente.tipoDni,
        cuitCuil: cliente.numeroCuilCuit,
      },
      domicilio: {
        provincia: cliente.provincia,
        municipio: cliente.municipio,
        calle: cliente.calle,
        altura: cliente.altura,
        pisoDepto: cliente.pisoDepto,
        cp: cliente.cp,
      },
      telefono: {
        movil: cliente.telefonoMovil,
        particular: cliente.telefonoFijo,
      },
      nacionalidad: cliente.nacionalidad,
      email: cliente.email,
      fechaNacimiento: cliente.fechaNacimiento,
      estadoCivil: cliente.estadoCivil,
      nupcias: cliente.nupcias,
      datosConyuge: {
        cuil: cliente.cuilConyuge,
        nombre: cliente.nombreConyuge,
        segundoNombre: cliente.segundoNombreConyuge,
        apellido: cliente.apellidoConyuge,
        apellidoMaterno: cliente.apellidoMaternoConyuge,
      },
      sexo: cliente.sexo,
      activo: cliente.activo,
      inmobiliaria: cliente.inmobiliaria,
      razonSocial: cliente.razonSocial,
    };

    try {
      const rta = await clienteAxios.put(
        `/clientes/${cliente._id}`,
        datosEnviar,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (rta.data.estado === "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado === "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      props.history.goBack();
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const eliminarCliente = async (e) => {
    e.preventDefault();
    try {
      const rta = await clienteAxios.delete(`/clientes/${cliente._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (rta.data.estado === "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado === "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      props.history.push("/clientes");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const actualizarStateV2 = (data) => {
    guardarCliente({
      ...cliente,
      ...data
    });
  }

  const findOperacionesByClientId = async () => {
    try {
      const { id } = props.match.params;
      const operacionesDb = await clienteAxios.get(`/operaciones/getByCliente/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOperaciones(operacionesDb.data);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  if (!cliente._id) {
    return <Spinner />;
  }

  moment.locale("es-Ar");

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title mt-2'>Editar Cliente</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className='card-body'>
        <form>
          <FormularioCliente
            actualizarState={actualizarState}
            cliente={cliente}
            actualizarStateV2={actualizarStateV2}
          />

          <div className="row">
            <div className="col">
              <h3 className="card-title mb-4">Operaciones por Cliente</h3>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table table-striped">
                <thead>
                  <tr className="text-center">
                    <th>Nro Operación</th>
                    <th>Desarrollo</th>
                    <th>Lotes</th>
                    <th>Fecha</th>
                    <th>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {operaciones.map(operacion => (
                    <tr key={operacion._id} className="text-center">
                      <td>{operacion.id}</td>
                      <td>{operacion.lote[0].barrio.nombre}</td>
                      <td>{operacion.lote.reduce((acc, lote, index) => acc + lote.numeroLote + (index !== operacion.lote.length - 1 ? ', ' : ''), '')}</td>
                      <td>{moment(operacion.fechaAlta).format('DD/MM/YYYY')}</td>
                      <td>{operacion.estado.estado}</td>
                      <td>
                        <button className="btn btn-primary"
                          onClick={() => props.history.push(`/operaciones/${operacion._id}`)}
                        >Ver</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='card-footer'>
            <button className='btn btn-danger' onClick={eliminarCliente}>
              Eliminar
            </button>

            <button
              type='submit'
              className='btn btn-primary float-right'
              disabled={validarCliente()}
              onClick={agregarCliente}
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(EditarCliente);
