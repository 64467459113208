import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const isPersonaFisica = (cliente) => {
  return !!cliente?.nombre;
}

const isPersonaJuridica = (cliente) => {
  return !!cliente?.razonSocial;
}

const getNombre = (cliente) => {
  if (isPersonaJuridica(cliente)) {
    return <NombreRazonSocial cliente={cliente} />
  } else if (isPersonaFisica(cliente)) {
    return <NombrePersonaFisica cliente={cliente} />
  }
}

const getDocumentos = (cliente) => {
  if (isPersonaJuridica(cliente)) {
    return <DocumentosPersonaJuridica cliente={cliente} />
  } else if (isPersonaFisica(cliente)) {
    return <DocumentosPersonaFisica cliente={cliente} />
  }
}

const formatCuilCuit = (original) => {
  if (!original) {
    return '';
  }
  if (!isNaN(original)) {
    original = original.toString();
  }
  const part1 = original.slice(0, 2);
  const part2 = original.slice(2, 10);
  const part3 = original.slice(10);
  return `${part1}-${part2}-${part3}`;
}

const DocumentosPersonaJuridica = ({ cliente }) => {
  const { documentos } = cliente;
  return (
    <>
      <div className="row">
        <label>CUIT:</label>
      </div>
      <div className="row">
        <p>{formatCuilCuit(documentos.cuitCuil)}</p>
      </div>
    </>
  )
}
const DocumentosPersonaFisica = ({ cliente }) => {
  const { documentos } = cliente;
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <label>DNI Tipo:</label>
          </div>
          <div className="row">
            <p>{documentos.dniTipo}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <label>DNI Número:</label>
          </div>
          <div className="row">
            <p>{new Intl.NumberFormat('es-Ar').format(documentos.dni)}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <label>CUIL:</label>
          </div>
          <div className="row">
            <p>{formatCuilCuit(documentos.cuitCuil)}</p>
          </div>
        </div>
      </div>
    </>
  )
}

const NombreRazonSocial = ({ cliente }) => {
  return (
    <>
      <div className="row">
        <label htmlFor="">Razón Social:</label>
      </div>
      <div className="row">
        <p>{cliente.razonSocial}</p>
      </div>
    </>
  )
}
const NombrePersonaFisica = ({ cliente }) => {
  const { nombre } = cliente;
  return (
    <>
      <div className="row">
        <label htmlFor="">Nombre Completo:</label>
      </div>
      <div className="row">
        <p>{nombre.apellido} {nombre.apellidoMaterno?.toUpperCase()}, {nombre.nombre}</p>
      </div>
    </>
  )
}

const Domicilio = ({ cliente }) => {

  const { domicilio } = cliente;

  if (!domicilio) {
    return (
      <></>
    )
  }
  const { altura, calle, cp, municipio, pisoDepto, provincia } = domicilio;

  return (
    <>
      <div className="row mb-1">
        <label htmlFor="">Domicilio: </label>
      </div>
      <div className="row">
        {provincia &&
          <div className="col-md-4">
            <div className="row">
              <label htmlFor="">Provincia:</label>
            </div>
            <div className="row">
              <p>{provincia}</p>
            </div>
          </div>
        }
        {municipio &&
          <div className="col-md-4">
            <div className="row">
              <label htmlFor="">Municipio:</label>
            </div>
            <div className="row">
              <p>{municipio}</p>
            </div>
          </div>
        }
        {cp &&
          <div className="col-md-4">
            <div className="row">
              <label htmlFor="">CP:</label>
            </div>
            <div className="row">
              <p>{cp}</p>
            </div>
          </div>
        }
      </div>
      <div className="row">
        {calle &&
          <div className="col-md-4">
            <div className="row">
              <label htmlFor="">Calle:</label>
            </div>
            <div className="row">
              <p>{calle}</p>
            </div>
          </div>
        }
        {altura &&
          <div className="col-md-4">
            <div className="row">
              <label htmlFor="">Altura:</label>
            </div>
            <div className="row">
              <p>{altura}</p>
            </div>
          </div>
        }
        {pisoDepto &&
          <div className="col-md-4">
            <div className="row">
              <label htmlFor="">Piso / Depto:</label>
            </div>
            <div className="row">
              <p>{pisoDepto}</p>
            </div>
          </div>
        }
      </div>
    </>
  )
}

const GetTelefono = (cliente) => {
  const { telefono } = cliente.cliente;
  if (!telefono) {
    return <>
      <p>Al cliente no se le cargaron los teléfonos</p>
    </>;
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4 form-group">
          <label>Teléfono Móvil</label>
          <p>{telefono?.movil || '-'}</p>
        </div>
        <div className="col-md-4 form-group">
          <label>Teléfono Fijo (Sin 0)</label>
          <p>{telefono.particular || '-'}</p>
        </div>
      </div>
    </>
  )
}

export default function MostrarFirmante({ cliente, titulo = 'Datos de la persona' }) {

  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };


  return (
    <>
      <div className="card-header">
        <h3 className="card-title">{titulo}</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="mb-2">
                {getNombre(cliente)}
              </div>
            </div>
            <div className="col">
              <Link to={`/clientes/editar/${cliente._id}`}>
                <button
                  className="btn btn-primary float-right"
                  type="button"
                >
                  Editar Cliente
                </button>
              </Link>
            </div>
          </div>

          <div className="mb-2">
            {getDocumentos(cliente)}
          </div>
          <div className="mb-2">
            <Domicilio
              cliente={cliente}
            />
          </div>

          <div className="mb-2">
            <GetTelefono cliente={cliente} />
          </div>

          {cliente.estadoCivil &&
            <>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="">Estado Civil</label>
                  <p>{cliente.estadoCivil.nombre}</p>
                </div>
              </div>
            </>
          }
          {cliente && cliente.datosConyuge && cliente.estadoCivil.nombre !== 'Soltero' ?
            <>
              <div className="row">
                <div className="col-md-6">
                  <label>Datos del Conyuge</label>
                  <p>{cliente.datosConyuge.apellido ? cliente.datosConyuge.apellido : null} {cliente.datosConyuge.nombre ? `(${cliente.datosConyuge.nombre})` : null}</p>
                </div>
                <div className="col-md-6">
                  <label>CUIL del Conyuge</label>
                  <p>{cliente.datosConyuge.cuil ? formatCuilCuit(cliente.datosConyuge.cuil) : ' - '}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>Nupcias</label>
                  <p>{cliente.nupcias ? cliente.nupcias : ' - '}</p>
                </div>
              </div>
            </> : null}


        </div>
      </div>
    </>
  )
}
