import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ExternalPropertyUtils from '../wizardFormaPago/utils/ExternalPropertyUtils';
import clienteAxios from '../../../../../config/axios';
import moment from 'moment';
import ShowPersonFromCUIX from '../../../../clientes/components/ShowPersonFromCUIX';
import TablaCotizaciones from "../../../components/TablaCotizaciones";
import GetRedondeo from "./GetRedondeo";
import GetFinanciacion from "./GetFinanciacion";

const GetComisionAsesor = ({ operacion, guardarOperacion }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <>
      < div className="card-header">
        <h3 className="card-title">Comisión del Asesor Inmobiliario</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>


        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <label>Comisión Asesor Inmobiliario</label>
              <p>{operacion.comisionAsesorInmobiliario} %</p>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

const moneyArFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const moneyUsFormatter = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const formatCuilCuit = (original) => {
  if (!original) {
    return '';
  }
  if (!isNaN(original)) {
    original = original.toString();
  }
  const part1 = original.slice(0, 2);
  const part2 = original.slice(2, 10);
  const part3 = original.slice(10);
  return `${part1}-${part2}-${part3}`;
}

const GetEfectivo = ({ efectivo, operacion, guardarOperacion }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const actualizarEfectivo = (e, index) => {
    const value = e.floatValue;
    if (!value) {
      return;
    }
    const operacionCopy = Object.assign({}, operacion);
    operacionCopy.formaDePago.anticipo.efectivo[index].valores.pesosBoleto = value;
    guardarOperacion({
      ...operacionCopy
    });
  }

  return (
    <>
      < div className="card-header">
        <h3 className="card-title">Efectivo</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <div className="row">
            <div className="col">
              {efectivo.map((eft, index) => {
                return (
                  <>
                    <div className='mt-4' key={index}>
                      <label>{`Entrega de: ${eft.moneda === 'usd' ? 'DÓLARES' : 'PESOS ARGENTINOS'}`}</label>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>Moneda</th>
                            <th>Monto Original</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={index}>
                            <td>{eft.moneda === 'usd' ? 'USD' : 'ARS'}</td>
                            <td>{eft.moneda === 'usd' ? moneyUsFormatter.format(eft.monto) : moneyArFormatter.format(eft.monto)}</td>
                          </tr>
                        </tbody>
                      </table>
                      <TablaCotizaciones allReadOnly={true} showBoleto={true} cotizaciones={eft.valores} setCotizaciones={() => { }} />
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const GetEntregas = ({ entregas, operacion, guardarOperacion }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const actualizarEntrega = (value, index) => {
    const operacionCopy = Object.assign({}, operacion);
    operacionCopy.formaDePago.entregas[index] = value;
    guardarOperacion({
      ...operacionCopy,
    });
  }

  return (
    <>
      <div className="card-header">
        <h3 className="card-title">Entregas</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <div className="row">
            <div className="col">
              {entregas.map((entrega, index) => {
                const actualizarEftLocal = (valores) => {
                  entrega.valores = valores;
                  actualizarEntrega(entrega, index);
                }
                return (
                  <div className='mt-4' key={index}>
                    <label>{`Entrega de: ${entrega.tipo}`}</label>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Tipo de Entrega</th>
                          <th>Descripción</th>
                          <th>Moneda</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={index}>
                          <td>{entrega.referencia.tipo}</td>
                          <td>{entrega.referencia.descripcion}</td>
                          <td>{entrega.moneda === 'usd' ? 'USD' : 'ARS'}</td>
                        </tr>
                      </tbody>
                    </table>
                    {entrega.referencia.prestador ?
                      <div className="row mt-4">
                        <div className="col">
                          <label>Información del Prestador:</label>
                          <ShowPersonFromCUIX taxId={entrega.referencia.prestador} />
                        </div>
                      </div>
                      : null}
                    <TablaCotizaciones allReadOnly={true} showBoleto={true} cotizaciones={entrega.referencia.valores} setCotizaciones={() => { }} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const GetInmueble = ({ inmueble, operacion, guardarOperacion, token, ...props }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  const [provincias, setProvincias] = useState([]);
  const [localidades, setLocalidades] = useState([]);

  useEffect(() => {
    consultarAPIProvincias();
    consultarAPILocalidades(inmueble.descripcion.provincia);
  }, []);


  const consultarAPIProvincias = async () => {
    try {
      const response = await clienteAxios.get("/provinciasLocalidades/provincias", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const orderedStates = response.data.sort((a, b) => {
        return a.nombreProvincia > b.nombreProvincia ? 1 : -1
      });

      setProvincias(orderedStates.map(state => ({
        label: state.nombreProvincia,
        value: state.idProvincia,
      })))

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const consultarAPILocalidades = async (provincia) => {
    if (!provincia) {
      return;
    }
    try {
      const response = await clienteAxios.get(`/provinciasLocalidades/localidades/${provincia}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const orderedCities = response.data.sort((a, b) => {
          return a.nombreLocalidad > b.nombreLocalidad ? 1 : -1
        });

        setLocalidades(orderedCities.map(city => ({
          label: city.nombreLocalidad,
          value: city._id,
        })))
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const actualizarCambioBoleto = (e) => {
    const value = e.floatValue;
    if (!value) {
      return;
    }
    const operacionCopy = Object.assign({}, operacion);
    operacion.formaDePago.anticipo.inmueble.valores.cambioBoleto = e.floatValue;
    guardarOperacion({
      ...operacionCopy
    });
  }

  const actualizarMontoBoleto = (e) => {
    const value = e.floatValue;
    if (!value) {
      return;
    }
    const operacionCopy = Object.assign({}, operacion);
    operacion.formaDePago.anticipo.inmueble.descripcion.valores.pesosBoleto = e.floatValue;
    guardarOperacion({
      ...operacionCopy
    });
  }

  return (
    <>
      <div className="card-header">
        <h3 className="card-title">Inmuebles</h3>
        <button
          className="btn btn-primary float-right ml-3"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
        <Link to={`/bienes/${operacion.formaDePago.anticipo.inmueble._id}`}>
          <button
            className="btn btn-success float-right mr-3"
            type="button">
            Ver en Bienes
          </button>
        </Link>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <>
            <div className="row">
              <label className="col-md-12">Ubicación</label>
              <div className='col-md-4 form-group'>
                <label>Provincia</label>
                <p>{provincias.length > 0 && ExternalPropertyUtils.getSelectedOptionExternal(provincias, inmueble.descripcion.provincia)?.label}</p>
              </div>
              {localidades && localidades.length > 0 &&
                <>
                  <div className='col-md-4 form-group'>
                    <label>Localidad</label>
                    <p>{localidades.length > 0 && ExternalPropertyUtils.getSelectedOptionExternal(localidades, inmueble.descripcion.localidad)?.label}</p>
                  </div>
                </>
              }
              {inmueble.descripcion.direccion &&
                <div className="col-md-12">
                  <label>Dirección:</label>
                  <p>{inmueble.descripcion.direccion}</p>
                </div>
              }
            </div>


            <div className="row">
              <label className="col-md-12">Características</label>
              <div className='col-md-3 form-group'>
                <label>Tipo de Propiedad</label>
                <p>{ExternalPropertyUtils.getSelectedOption('types', inmueble.descripcion?.type || null)?.label}</p>
              </div>

              <div className='col-md-3 form-group'>
                <label>Ambientes</label>
                <p>{ExternalPropertyUtils.getSelectedOption('ambientes', inmueble.descripcion?.ambientes)?.label}</p>
              </div>

              <div className='col-md-3 form-group'>
                <label>Dormitorios</label>
                <p>{ExternalPropertyUtils.getSelectedOption('dormitorios', inmueble.descripcion?.dormitorios)?.label}</p>
              </div>

              <div className='col-md-3 form-group'>
                <label>Baños</label>
                <p>{ExternalPropertyUtils.getSelectedOption('banos', inmueble.descripcion?.banos)?.label}</p>
              </div>

              <div className='col-md-3 form-group'>
                <label>Garage</label>
                <p>{ExternalPropertyUtils.getSelectedOption('garage', inmueble.descripcion?.garage)?.label}</p>
              </div>

            </div>

            <div className="row">
              <label className="col-md-12">Superficies</label>

              <div className="col-md-4 form-group">
                <label>Superficie Total en m2</label>
                <p>{inmueble.descripcion?.superficieTotal || null}</p>
              </div>

              <div className="col-md-4 form-group">
                <label>Superficie Cubierta en m2</label>
                <p>{inmueble.descripcion?.superficieCubierta || null}</p>
              </div>

              <div className="col-md-4 form-group">
                <label>Superficie Terreno en m2</label>
                <p>{inmueble.descripcion?.superficieTerreno || null}</p>
              </div>

            </div>


            <div className="row">
              <label className="col-md-12">Otros</label>

              <div className='col-md-4 form-group'>
                <label>Año de Construcción</label>
                <p>{ExternalPropertyUtils.getSelectedOption('anoConstruccion', inmueble.descripcion?.anoConstruccion)?.label}</p>
              </div>

              <div className='col-md-4 form-group'>
                <label>Moneda Expensas</label>
                <p>{inmueble.descripcion && inmueble.descripcion?.monedaExpensas ? ExternalPropertyUtils.getTipoMonedaByValue(inmueble.descripcion?.monedaExpensas)?.label : ''}</p>
              </div>

              <div className="col-md-4 form-group">
                <label>Expensas</label>
                <p>{moneyArFormatter.format(inmueble.descripcion?.expensas || null)}</p>
              </div>
            </div>
            <div className="row">
              <div className='col-md-6 form-group'>
                <label>Condición</label>
                <p>{ExternalPropertyUtils.getSelectedOption('condicion', inmueble.descripcion?.condicion)?.label}</p>
              </div>


            </div>

            <div className="row">
              <label className="col-md-12">Otras Características</label>

              <div className='col-md-12 form-group'>
                <label>Otros</label>
                <p>{ExternalPropertyUtils.getMultiSelectedOptions('otros', inmueble.descripcion?.otros || []).map(opt => opt.label).join(', ')}</p>
              </div>


            </div>

            <TablaCotizaciones allReadOnly={true} showBoleto={true} cotizaciones={inmueble.descripcion.valores} setCotizaciones={() => { }} />
          </>
        </div>
      </div>
    </>
  )
}

const GetLotes = ({ lotes, operacion, guardarOperacion }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const updateLote = (valor, index) => {
    const operacionCopy = Object.assign({}, operacion);
    operacionCopy.formaDePago.anticipo.lotes[index].valores.pesosBoleto = valor;
    guardarOperacion({
      ...operacionCopy,
    })
  }

  return (
    <>
      < div className="card-header">
        <h3 className="card-title">Entrega Lotes</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <label>Entrega de Lotes</label>
            </div>
          </div>

          {lotes.map((lote, index) => {

            const updateLoteUnitario = (valor) => {
              updateLote(valor, index);
            }

            return (
              <div className='border-bottom mb-4'>
                <div className='mb-4'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Desarrollo</th>
                        <th>Manzana</th>
                        <th>Número de lote</th>
                        <th>Superficie</th>
                        <th>Tipología</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{lote.lote.barrio.nombre}</td>
                        <td>{lote.lote.numeroManzana}</td>
                        <td>{lote.lote.numeroLote}</td>
                        <td>{lote.lote.superficie} m2</td>
                        <td>{lote.lote.tipologia.tipo}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <TablaCotizaciones allReadOnly={true} showBoleto={true} cotizaciones={lote.valores} setCotizaciones={() => { }} />
              </div>
            );
          })}

        </div>
      </div>
    </>
  )
}

const GetVehiculo = ({ vehiculo, operacion, guardarOperacion }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const actualizarValores = (valor) => {
    const operacionCopy = Object.assign({}, operacion);
    operacion.formaDePago.anticipo.vehiculo.valores.pesosBoleto = valor;
    guardarOperacion({
      ...operacionCopy
    });
  }

  return (
    <>
      < div className="card-header">
        <h3 className="card-title">Vehículo</h3>
        <button
          className="btn btn-primary float-right ml-3"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
        <Link to={`/bienes/${operacion.formaDePago.anticipo.vehiculo._id}`}>
          <button
            className="btn btn-success float-right ml-3"
            type="button">
            Ver en Bienes
          </button>
        </Link>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <div className="row">
            <div className='col-md-4 form-group'>
              <label>Tipo</label>
              <p>{vehiculo.descripcion.vehiculo.type}</p>
            </div>

            <div className='col-md-4 form-group'>
              <label>Marca</label>
              <p>{vehiculo.descripcion.vehiculo.brand}</p>
            </div>

            <div className='col-md-4 form-group'>
              <label>Modelo</label>
              <p>{vehiculo.descripcion.vehiculo.model}</p>
            </div>

          </div>
          <div className="row">

            <div className='col-md-4 form-group'>
              <label>Versión</label>
              <p>{vehiculo.descripcion.vehiculo.version}</p>
            </div>

            <div className='col-md-4 form-group'>
              <label>Año</label>
              <p>{vehiculo.descripcion.vehiculo.year}</p>
            </div>
          </div>
          <TablaCotizaciones allReadOnly={true} showBoleto={true} cotizaciones={vehiculo.descripcion.valores} setCotizaciones={() => { }} />
        </div>
      </div>
    </>
  )
}

const GetDocumentos = ({ vencimientosPuntuales, operacion, guardarOperacion }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const updateDocumentos = (valor, index) => {
    const operacionCopy = Object.assign({}, operacion);
    operacionCopy.formaDePago.vencimientosPuntuales[index].valores.pesosBoleto = valor;
    guardarOperacion({
      ...operacionCopy,
    });
  }

  return (
    <>
      <div className="card-header">
        <h3 className="card-title">Documentos</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          {vencimientosPuntuales.map((anticipo, index) => {

            const updateDocumentoUnitario = (valores) => {
              updateDocumentos(valores, index);
            }

            return (
              <div className='mt-4 mb-2 border-bottom'>
                <div className='card-header' key={index}>
                  <div className="row mb-2">
                    <label>Entrega de: {anticipo.tipo}</label>
                  </div>
                  <div className="row">
                    <div className='col-md-3 form-group'>
                      <label>Tipo</label>
                      <p>{anticipo.tipo}</p>
                    </div>
                    {anticipo && anticipo.tipo && anticipo.tipo !== 'cheque' &&
                      <div className='col-md-2 form-group'>
                        <label>Moneda</label>
                        <p>{anticipo.moneda}</p>
                      </div>
                    }

                    <div className='col-md-3 form-group'>
                      <label>Monto</label>
                      <p>{anticipo.moneda === 'usd' ? moneyUsFormatter.format(anticipo.monto) : moneyArFormatter.format(anticipo.monto)}</p>
                    </div>

                    <div className="form-group col-md-3">
                      <label>Fecha de Pago</label>
                      <p>{moment(anticipo.vencimiento)
                        .utcOffset(180)
                        .format("DD-MM-YYYY")}</p>
                    </div>
                  </div>
                  {
                    anticipo && anticipo.tipo === 'cheque' &&

                    <>
                      <div className="row">
                        <div className='col-md-3 form-group'>
                          <label>Número</label>
                          <p>{anticipo.numeroCheque}</p>
                        </div>
                        <div className='col-md-3 form-group'>
                          <label>Banco</label>
                          <p>{anticipo.banco}</p>
                        </div>
                        <div className='col-md-3 form-group'>
                          <label>Plaza</label>
                          <p>{anticipo.plaza}</p>
                        </div>
                        <div className='col-md-3 form-group'>
                          <label>Nro Sucursal</label>
                          <p>{anticipo.sucursal}</p>
                        </div>
                        <div className='col-md-3 form-group'>
                          <label>CUIT/CUIL librador</label>
                          <p>{formatCuilCuit(anticipo.cuitLibrador)}</p>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col">
                          <label>Información del Librador del Cheque:</label>
                          <ShowPersonFromCUIX taxId={anticipo.cuitLibrador} />
                        </div>
                      </div>
                    </>
                  }
                </div>
                <TablaCotizaciones allReadOnly={true} showBoleto={true} cotizaciones={anticipo.valores} setCotizaciones={() => { }} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default function ConfirmarValoresBoleto({
  operacion,
  saldoAFinanciar,
  title = 'Valores Boleto',
  guardarOperacion,
  enviarOperacion,
  token
}) {
  const { formaDePago } = operacion;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  const { anticipo, fechaIndiceCac, entregas, financiacion, vencimientosPuntuales } = formaDePago;
  const { efectivo, lotes, inmueble, vehiculo } = anticipo;

  return (
    <>
      < div className="card-header">
        <h3 className="card-title">{title}</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>


        <div className="card-body">

          {efectivo && efectivo.length > 0 &&
            <GetEfectivo efectivo={efectivo} operacion={operacion} guardarOperacion={guardarOperacion} />
          }


          {entregas && entregas.length > 0 &&
            <GetEntregas entregas={entregas} guardarOperacion={guardarOperacion} operacion={operacion} />
          }

          {inmueble &&
            <GetInmueble inmueble={inmueble} operacion={operacion} guardarOperacion={guardarOperacion} token={token} />
          }

          {lotes && lotes.length > 0 &&
            <>
              <GetLotes lotes={lotes} operacion={operacion} guardarOperacion={guardarOperacion} token={token} />
            </>
          }

          {vehiculo &&
            <>
              <GetVehiculo vehiculo={vehiculo} operacion={operacion} guardarOperacion={guardarOperacion} token={token} />
            </>
          }

          {vencimientosPuntuales && vencimientosPuntuales.length > 0 &&
            <>
              <GetDocumentos vencimientosPuntuales={vencimientosPuntuales} operacion={operacion}
                guardarOperacion={guardarOperacion} />
            </>
          }
          <>
            <GetRedondeo operacion={operacion} setOperacion={guardarOperacion} disableOptions={true} />
          </>

          {financiacion &&
            <>
              <GetFinanciacion operacion={operacion} guardarOperacion={guardarOperacion} />
            </>
          }
          <GetComisionAsesor operacion={operacion} guardarOperacion={guardarOperacion} />
          <div className="row mt-3 mb-5">
            <div className="col">
              <button
                className="btn btn-success float-right"
                type="button"
                onClick={enviarOperacion}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


