import axios from "axios";
import useAuthStore from "../store/authStore";

const clienteAxios = axios.create({
  // baseURL: "http://localhost:4000",
  // baseURL: "https://vps-2799509-x.dattaweb.com:4000",
  // baseURL: "https://life.herokuapp.com/",
  // baseURL: "https://life-api-hqls9.ondigitalocean.app/"
  baseURL: process.env.REACT_APP_BACKEND_URL
});

clienteAxios.interceptors.request.use(
  config => {
    let token = useAuthStore.getState().token; // Get token from Zustand auth store
    if(!token) {
      token = localStorage.getItem("token"); // Get token from localStorage
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);


export default clienteAxios;
