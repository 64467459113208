import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';
import { useForm } from 'react-hook-form';

export const FormularioDocumentacion = (props) => {

  const [auth, guardarAuth] = useContext(CRMContext);
  const [categorias, setCategorias] = useState([]);
  let token = auth.token || localStorage.getItem("token");
  const { register, handleSubmit } = useForm();

  const getCategorias = async () => {
    try {
      const response = await clienteAxios.get("/api/documentacion/categorias", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategorias(response.data);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    getCategorias();
  }, []);

  const submit = async (data) => {
    try {
      const datosEnviar = new FormData();
      const keys = [...Object.keys(data), 'archivo'];
      keys.forEach(key => {
        datosEnviar.append(key, data[key]);
      });
      for (let i = 0; i < data.archivo.length; i++) {
        datosEnviar.append('archivo', data.archivo[i]);
      }
      const response = await clienteAxios.post(`/api/documentacion/`, datosEnviar, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.msg === 'ok') {
        props.history.push('/documentacion/listado');
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title mt-2">Documentación</h3>
          </div>
          <div className="col-md-4">
            <input type="button" value="Volver" className="btn btn-primary mr-3 float-right" onClick={props.history.goBack} />
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label>Categoria</label>
                <select name="categoria" className="form-control" {...register("categoria")}>
                  {categorias.map(categoria => (
                    <option key={categoria._id} value={categoria._id}>{categoria.nombre}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Comentario</label>
                <textarea name="descripcion" className="form-control" {...register("comentario")}></textarea>
              </div>
              <div className="form-group">
                <label>Fecha del Documento</label>
                <input type='date' name="fechaDocumentos" className="form-control" {...register("fechaDocumentos")} />
              </div>
              <div className="form-group">
                <label>Archivo</label>
                <input type="file" name="archivo" className="form-control" {...register("archivo")} multiple />
              </div>
              <input type="submit" value="Guardar" className="btn btn-primary" />
            </form>
          </div>
        </div>
      </div>
      <div className="card-footer"></div>
    </div>
  )
}
