import React, { useEffect, useState } from 'react';
import moment from "moment/moment";
import NumberFormat from "react-number-format";

const moneyArFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const moneyUsFormatter = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

function GetFinanciacion({ operacion, guardarOperacion, onlyShow = true }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const rolId = parseInt(localStorage.getItem('rolId'));
  const isBackOffice = rolId == 3 || rolId == 4 || rolId == 6;
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const { formaDePago } = operacion;
  const { financiacion, financiaciones, anticipo, vencimientosPuntuales, entregas } = formaDePago;

  const getTotalEntregas = () => {
    let total = 0;

    if (anticipo) {
      if (anticipo.efectivo && Array.isArray(anticipo.efectivo)) {
        anticipo.efectivo.forEach(efectivo => {
          total += !isNaN(efectivo.valores?.pesosNegociado) ? efectivo.valores.pesosNegociado : 0;
        });
      }

      if (anticipo.inmueble) {
        total += !isNaN(anticipo.inmueble.descripcion.valores?.pesosNegociado) ? anticipo.inmueble.descripcion.valores.pesosNegociado : 0;
      }

      if (Array.isArray(anticipo.lotes)) {
        anticipo.lotes.forEach(lote => {
          total += !isNaN(lote.valores?.pesosNegociado) ? lote.valores.pesosNegociado : 0;
        });
      }

      if (anticipo.vehiculo && anticipo.vehiculo.descripcion.valores && anticipo.vehiculo.descripcion.valores.pesosNegociado) {
        total += !isNaN(anticipo.vehiculo.descripcion.valores.pesosNegociado) ? anticipo.vehiculo.descripcion.valores.pesosNegociado : 0;
      }
    }

    if (Array.isArray(vencimientosPuntuales)) {
      vencimientosPuntuales.forEach(vencimiento => {
        total += !isNaN(vencimiento.valores?.pesosNegociado) ? vencimiento.valores.pesosNegociado : 0;
      });
    }

    if (entregas && Array.isArray(entregas)) {
      entregas.forEach(entrega => {
        total += !isNaN(entrega.referencia?.valores?.pesosNegociado) ? entrega.referencia?.valores?.pesosNegociado : 0;
      });
    }

    return total;
  }

  const totalEntregas = getTotalEntregas();
  const { redondeo } = operacion.formaDePago;
  let valorPesosRedondeo = 0;
  if (redondeo && redondeo.valorEnPesos && redondeo.valorEnPesos > 0) {
    valorPesosRedondeo = redondeo.valorEnPesos;
  }

  const getValorCuotaNegociado = () => {
    return (operacion.precioVentaOperacion - totalEntregas - valorPesosRedondeo) / financiacion.cantidadCuotas
  }

  const getValorCuotaNegociadoDolares = () => {
    return getValorCuotaNegociado() / financiacion.cambioNegociado;
  }


  const totalFinanciaciones = financiaciones.reduce((acc, financiacion) => {
    return acc + (financiacion.monedaSeleccionada === 'ars' ? financiacion.valorCuotaPropuesto * financiacion.cantidadCuotas / financiacion.cambioPropuesto : financiacion.valorCuotaPropuestoDolares * financiacion.cantidadCuotas);
  }, 0);

  const updateValorCuotaNegociado = () => {

    financiaciones.forEach((financiacion, index) => {

      const getValorCuotaNegociado = () => {
        return (operacion.precioVentaOperacion - totalEntregas - valorPesosRedondeo) / financiacion.cantidadCuotas
      }

      const getValorCuotaNegociadoDolares = () => {
        return getValorCuotaNegociado() / financiacion.cambioNegociado;
      }

      const totalFinanciacion = financiacion.monedaSeleccionada === 'ars' ? financiacion.valorCuotaPropuesto * financiacion.cantidadCuotas / financiacion.cambioPropuesto : financiacion.valorCuotaPropuestoDolares * financiacion.cantidadCuotas;

      if (operacion.formaDePago.financiacion) {
        const operacionCopy = Object.assign({}, operacion);
        operacionCopy.formaDePago.financiaciones[index].valorCuotaNegociado = getValorCuotaNegociado() * totalFinanciacion / totalFinanciaciones;
        operacionCopy.formaDePago.financiaciones[index].valorCuotaNegociadoDolares = getValorCuotaNegociadoDolares() * totalFinanciacion / totalFinanciaciones;
        guardarOperacion({ ...operacionCopy });
      }
    });
  }

  useEffect(() => {
    if (onlyShow) {
      return;
    }
    updateValorCuotaNegociado();
  }, [operacion.precioVentaOperacion, totalEntregas, valorPesosRedondeo]);

  const setCambioNegociado = (e) => {
    const cambio = e.floatValue;
    if (cambio > 0) {
      const operacionCopy = { ...operacion }
      operacionCopy.formaDePago.financiacion.cambioNegociado = cambio;
      guardarOperacion({ ...operacionCopy });
    }
  }


  return (
    <>
      < div className="card-header">
        <h3 className="card-title">Financiación</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <label>Financiación: </label>

          {financiaciones && financiaciones.length > 0 && financiaciones.map((financiacion, index) => {

            const getValorCuotaNegociado = () => {
              return (operacion.precioVentaOperacion - totalEntregas - valorPesosRedondeo) / financiacion.cantidadCuotas
            }

            const getValorCuotaNegociadoDolares = () => {
              return getValorCuotaNegociado() / financiacion.cambioNegociado;
            }

            const totalFinanciacion = financiacion.monedaSeleccionada === 'ars' ? financiacion.valorCuotaPropuesto * financiacion.cantidadCuotas / financiacion.cambioPropuesto : financiacion.valorCuotaPropuestoDolares * financiacion.cantidadCuotas;


            return (
              <div key={index}>
                <p>{`La fecha del primer pago es el ${moment(
                  financiacion.fechaPagoPrimerCuota
                ).utcOffset(180).format("DD/MM/YYYY")}`}</p>
                <table className='table text-center'>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Moneda</th>
                      <th>Cantidad de Cuotas</th>
                      <th>Valor de cuota</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Propuesto</td>
                      <td>{financiacion.monedaSeleccionada}</td>
                      <td>{financiacion.cantidadCuotas}</td>
                      <td>{financiacion.monedaSeleccionada === 'ars' ? moneyArFormatter.format(financiacion.valorCuotaPropuesto) : moneyUsFormatter.format(financiacion.valorCuotaPropuestoDolares)}</td>
                    </tr>
                    <tr>
                      <td>Negociado</td>
                      <td>{financiacion.monedaSeleccionada}</td>
                      <td>{financiacion.cantidadCuotas}</td>
                      <td>{financiacion.monedaSeleccionada === 'ars' ? moneyArFormatter.format(getValorCuotaNegociado() * totalFinanciacion / totalFinanciaciones) : moneyUsFormatter.format(getValorCuotaNegociadoDolares() * totalFinanciacion / totalFinanciaciones)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
          )}

          {financiacion.valorCuotaPropuesto && financiacion.valorCuotaPropuesto * financiacion.cantidadCuotas === 0 &&
            <p>Sin Financiación CAC</p>
          }
          {financiacion.valorCuotaPropuesto && financiacion.valorCuotaPropuesto * financiacion.cantidadCuotas !== 0 &&
            <>
              <p>{`La fecha del primer pago es el ${moment(
                financiacion.fechaPagoPrimerCuota
              ).utcOffset(180).format("DD/MM/YYYY")}`}{financiacion.monedaSeleccionada === 'ars' ? `, el mes de base para el cálculo CAC es el ${moment(
                operacion.formaDePago.fechaIndiceCac
              ).utcOffset(180).format("MM/YYYY")}` : ''}</p>
              {financiacion.monedaSeleccionada === 'usd' ?
                <div className="row">
                  <div className="col-md-3 mb-3 mt-3">
                    <label>Cambio de Financiación Propuesto</label>
                    <NumberFormat
                      className="form-control text-center"
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      prefix={'$ '}
                      value={financiacion.cambioPropuesto}
                      disabled
                    />
                  </div>
                  <div className="col-md-3 mb-3 mt-3">
                    <label>Cambio de Financiación Negociado</label>
                    <NumberFormat
                      className="form-control text-center"
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      prefix={'$ '}
                      onValueChange={setCambioNegociado}
                      value={financiacion.cambioNegociado}
                      disabled={!isBackOffice || operacion.estado.id !== 100}
                    />
                  </div>
                </div> : null
              }
              <table className='table text-center'>
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Saldo a Financiar</th>
                    <th>Cantidad de Cuotas</th>
                    <th>Valor de cuota</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Propuesto</td>
                    <td>{
                      isNaN(financiacion.valorCuotaPropuesto * financiacion.cantidadCuotas) ? 'n/c' :
                        financiacion.monedaSeleccionada === 'usd' ?
                          moneyUsFormatter.format(financiacion.valorCuotaPropuestoDolares * financiacion.cantidadCuotas)
                          : moneyArFormatter.format(financiacion.valorCuotaPropuesto * financiacion.cantidadCuotas)
                    }
                    </td>
                    <td>{financiacion.cantidadCuotas}</td>
                    <td>{
                      isNaN(financiacion?.valorCuotaPropuesto) ? 'n/c' :
                        financiacion.monedaSeleccionada === 'usd' ?
                          moneyUsFormatter.format(financiacion.valorCuotaPropuestoDolares)
                          : moneyArFormatter.format(financiacion?.valorCuotaPropuesto)
                    }
                    </td>
                  </tr>
                  <tr>
                    <td>Negociado</td>
                    <td>{
                      financiacion.monedaSeleccionada === 'usd' ?
                        moneyUsFormatter.format((operacion.precioVentaOperacion - totalEntregas - valorPesosRedondeo) / operacion.formaDePago.financiacion.cambioNegociado) :
                        moneyArFormatter.format(operacion.precioVentaOperacion - totalEntregas - valorPesosRedondeo)}</td>
                    <td>{financiacion.cantidadCuotas}</td>
                    <td>{
                      financiacion.monedaSeleccionada === 'usd' ?
                        moneyUsFormatter.format(operacion.formaDePago.financiacion.valorCuotaNegociadoDolares) :
                        moneyArFormatter.format(
                          operacion.formaDePago.financiacion.valorCuotaNegociado
                        )}</td>
                  </tr>
                </tbody>
              </table>
            </>}
        </div>
      </div>
    </>
  )
}


export default GetFinanciacion;