import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { useParams } from 'react-router-dom';

import { get } from '../../../config/apiHelper';
import listadoLotesStore from '../../../store/listadoLotesStore';

const LotesFiltros = ({ fetchApi, history }) => {
  const { filter, setFilter } = listadoLotesStore();
  let { id: barrioId } = useParams();
  if (!barrioId) {
    barrioId = 'all';
  }

  const [tipologias, setTipologias] = useState([]);
  const [estadosLotes, setEstadosLotes] = useState([]);
  const [inversionistas, setInversionistas] = useState([]);

  const isInversionistaOptions = [
    { value: null, label: 'Todos' },
    { value: true, label: 'Si' },
    { value: false, label: 'No' }
  ];

  const consultarAPITipologias = async () => {
    try {

      const response = await get("/tipologias");

      let tipologiasSelect = response.map((tipologia) => {
        return {
          value: tipologia._id,
          label: tipologia.tipo,
        };
      });
      tipologiasSelect.unshift({
        value: null,
        label: 'Todas'
      })
      setTipologias(tipologiasSelect);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const consultarAPIEstadosLotes = async () => {
    try {
      const response = await get("/estadosLotes");
      let estadosLotes = response.map((estado) => {
        return {
          value: estado.id,
          label: estado.estado,
        };
      });
      estadosLotes.unshift({
        value: null,
        label: 'Todos'
      })
      setEstadosLotes(estadosLotes);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const consultarApiInversionistas = async () => {
    try {
      const response = await get("/api/inversionistas");
      let inversionistasSelect = response.data.map((inversionista) => {
        return {
          value: inversionista._id,
          label: inversionista.nombreCorto,
        };
      }).sort((a, b) => a.label.localeCompare(b.label));
      inversionistasSelect.unshift({
        value: null,
        label: 'Todos'
      })
      setInversionistas(inversionistasSelect);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    consultarAPITipologias();
    consultarAPIEstadosLotes();
    consultarApiInversionistas();
  }, []);

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      fetchApi();
    }
  }


  return (
    <div className="form-group">
      <label>Filtros:</label>
      <div className="row mb-3">
        <div className="col-md-3">
          <label>Número de Lote:</label>
          <input
            type="text"
            onKeyDown={handleEnter}
            value={filter[barrioId]?.numeroLote || ''}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], numeroLote: e.target.value } })}
            placeholder="Número de Lote"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Número de Manzana:</label>
          <input
            type="number"
            onKeyDown={handleEnter}
            value={filter[barrioId]?.numeroManzana || ''}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], numeroManzana: e.target.value } })}
            placeholder="Número de Manzana"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Superficie Mínima:</label>
          <input
            type="number"
            onKeyDown={handleEnter}
            value={filter[barrioId]?.superficieMin || ''}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], superficieMin: e.target.value } })}
            placeholder="Superficie Mínima"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Superficie Máxima:</label>
          <input
            type="number"
            onKeyDown={handleEnter}
            value={filter[barrioId]?.superficieMax || ''}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], superficieMax: e.target.value } })}
            placeholder="Superficie Máxima"
            className="form-control"
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-3">
          <label>Tipología:</label>
          <Select
            options={tipologias}
            value={tipologias.find(tipologia => tipologia.value === filter[barrioId]?.tipologia)}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], tipologia: e.value } })}
          />
        </div>
        <div className="col-md-3">
          <label>Precio Mínimo:</label>
          <input
            type="number"
            onKeyDown={handleEnter}
            value={filter[barrioId]?.precioMin || ''}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], precioMin: e.target.value } })}
            placeholder="Precio Mínimo"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Precio Máximo:</label>
          <input
            type="number"
            onKeyDown={handleEnter}
            value={filter[barrioId]?.precioMax || ''}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], precioMax: e.target.value } })}
            placeholder="Precio Máximo"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Estado del Lote:</label>
          <Select
            options={estadosLotes}
            value={estadosLotes.find(estado => estado.value === filter[barrioId]?.estado)}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], estado: e.value } })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label>Es inversor:</label>
          <Select
            options={isInversionistaOptions}
            value={isInversionistaOptions.find(isInversor => isInversor.value === filter[barrioId]?.isInversor)}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], isInversor: e.value } })}
          />
        </div>
        <div className="col-md-3">
          <label>Inversionista:</label>
          <Select
            options={inversionistas}
            value={inversionistas.find(inversor => inversor.value === filter[barrioId]?.inversor)}
            onChange={e => setFilter({ ...filter, [barrioId]: { ...filter[barrioId], inversor: e.value } })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9"></div>
        <div className="col-md-3">
          <button className="btn btn-block btn-success float-right" onClick={fetchApi}>Consultar</button>
        </div>
      </div>
    </div>
  );
};


export default LotesFiltros;
