import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { CRMContext } from "../../context/CRMContext";
import clienteAxios from "../../config/axios";
import Spinner from "../layout/Spinner";
import AdjuntarArchivo from "./components/AdjuntarArchivo";
import Swal from "sweetalert2";

function ListaDePrecio(props) {
  const [listaPrecios, setListaPrecios] = useState({});
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const { id: idListaPrecio } = props.match.params;
  const urlBase = '/listaPrecios';


  useEffect(() => {
    getListaPrecio();
  }, []);


  const getListaPrecio = async () => {
    try {
      if (!idListaPrecio) {
        return;
      }
      const response = await clienteAxios.get(`${urlBase}/${idListaPrecio}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response)
      setListaPrecios(response.data.data);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  }

  if (!listaPrecios || !listaPrecios._id) {
    return <Spinner />
  }

  const handleInput = (e) => {
    const { name, value } = e.target;
    setListaPrecios({
      ...listaPrecios,
      [name]: value
    });
  }

  const updateListaPrecio = async () => {

    try {
      const rta = await clienteAxios.put(`${urlBase}/${idListaPrecio}`, listaPrecios, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    } finally {
      getListaPrecio();
    }
  }


  return (
    <>
      <div className="card-header">
        <h3 className="card-title">Configurar Lista de Precios</h3>
      </div>
      <div className="card-body mb-5">
        <form>
          <div className="row mt-4">
            <div className="col-md-6 form-group">
              <div className="row">
                <label>Imagen
                  Header{listaPrecios && listaPrecios.headerNombre ? `:  ${listaPrecios.headerNombre}` : null}</label>
              </div>
              <div className="row">
                <AdjuntarArchivo
                  consultarBarrio={getListaPrecio}
                  idBarrio={idListaPrecio}
                  imagePlace={'header'}
                />
              </div>
            </div>
            <div className="col-md-6 form-group">
              <div className="row">
                <label>Imagen
                  Footer{listaPrecios && listaPrecios.footerNombre ? `:  ${listaPrecios.footerNombre}` : null}</label>
              </div>
              <div className="row">
                <AdjuntarArchivo
                  consultarBarrio={getListaPrecio}
                  idBarrio={idListaPrecio}
                  imagePlace={'footer'}
                />
              </div>
            </div>
          </div>

          {/*<div className="row mt-4">*/}
          {/*  <div className="col-md-3 form-group">*/}
          {/*    <label>Color Primario:</label>*/}
          {/*    <input*/}
          {/*      type="color"*/}
          {/*      className="form-control"*/}
          {/*      placeholder="Ciudad"*/}
          {/*      name="ciudad"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="col-md-3 form-group">*/}
          {/*    <label>Color Secundario:</label>*/}
          {/*    <input*/}
          {/*      type="color"*/}
          {/*      className="form-control"*/}
          {/*      placeholder="Ciudad"*/}
          {/*      name="ciudad"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="col-md-3 form-group">*/}
          {/*    <label>Color Terciario:</label>*/}
          {/*    <input*/}
          {/*      type="color"*/}
          {/*      className="form-control"*/}
          {/*      placeholder="Ciudad"*/}
          {/*      name="ciudad"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="col-md-3 form-group">*/}
          {/*    <label>Título color blanco:</label>*/}
          {/*    <input*/}
          {/*      type="checkbox"*/}
          {/*      className="form-control"*/}
          {/*      placeholder="Ciudad"*/}
          {/*      name="ciudad"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="row mt-4">
            <div className="col-md-6 form-group">
              <label>Promoción:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Promoción"
                name="promocion"
                onChange={handleInput}
                value={listaPrecios.promocion ?? ''}
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Entrega:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Entrega"
                name="entrega"
                onChange={handleInput}
                value={listaPrecios.entrega ?? ''}
              />
            </div>
          </div>
          <div className="mt-4">
            <button
              onClick={(e) => {
                e.preventDefault();
                props.history.goBack();
              }}
              className="float-left btn btn-primary"
            >
              Volver
            </button>
            <button onClick={updateListaPrecio} className="btn btn-primary float-right">
              Guardar
            </button>
          </div>
        </form>

      </div>
    </>
  );
}

export default withRouter(ListaDePrecio);