import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import NumberFormat from "react-number-format";

import Spinner from "../layout/Spinner";
import moment from "moment";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { get } from "../../config/apiHelper";

const EditarLote = ({ history, ...props }) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const userId = localStorage.getItem('idUsuario');
  const [lote, setLote] = useState({ loading: true });
  const [barrios, setBarrios] = useState([]);
  const [tipologias, setTipologias] = useState([]);
  const [nuevoComentario, setNuevoComentario] = useState('');
  const [motivosDisponibilidad, setmotivosDisponibilidad] = useState([]);
  const [estadosLotes, setEstadosLotes] = useState([]);
  const [operacionesRelacionadas, setOperacionesRelacionadas] = useState([]);
  const [inversionistas, setInversionistas] = useState([]);

  const { id } = props.match.params;

  const getMotivosDisponibilidad = () => {
    const motivosFiltrados = motivosDisponibilidad.filter(motivo => {
      return lote.estado?.id === 0 ? motivo.disponible === true : motivo.disponible === false;
    });
    // return motivosFiltrados.map(motivo => ({label: motivo.nombre, value: motivo._id}));
    return motivosDisponibilidad.map(motivo => ({ label: motivo.nombre, value: motivo._id }));
  }

  const consultarAPImotivoDisponibilidad = async () => {
    try {
      const motivosDisponibilidadDB = await clienteAxios.get(`/motivoDisponibilidad?seleccionableUsuario=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setmotivosDisponibilidad(motivosDisponibilidadDB.data.data);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  // Obtener los datos del barrio a editar
  const consultarAPILote = async () => {
    try {
      const loteDB = await clienteAxios.get(`/lote/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLote({
        loading: false,
        ...loteDB.data,
      });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };
  // Obtener el listado de barrios de la API
  const consultarAPIBarrios = async () => {
    try {
      const barriosDB = await clienteAxios.get("/barrios", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBarrios(
        barriosDB.data.map((barrio) => {
          return {
            value: barrio._id,
            label: barrio.nombre,
          };
        })
      );
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  // Obtener el listado de tipologias de la API
  const consultarAPITipologias = async () => {
    try {
      const tipologiasDB = await clienteAxios.get("/tipologias", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTipologias(
        tipologiasDB.data.map((tipologia) => {
          return {
            value: tipologia._id,
            label: tipologia.tipo,
          };
        })
      );
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const consultarAPIEstadosLotes = async () => {
    try {
      const estadosDB = await clienteAxios.get("/estadosLotes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let estadosLotes = estadosDB.data.map((estado) => {
        return {
          value: estado.id,
          id: estado.id,
          label: estado.estado,
          estado: estado.estado,
          _id: estado._id
        };
      });

      setEstadosLotes(estadosLotes);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const getLoteOperaciones = async () => {
    try {
      const response = await clienteAxios.get(`operaciones/lote/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setOperacionesRelacionadas(response.data);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  const getInversionistas = async () => {
    try {
      const response = await get(`/api/inversionistas`);
      console.log("Inversionistas", response.data);
      setInversionistas(response.data);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getLoteOperaciones();
    consultarAPIEstadosLotes();
    consultarAPImotivoDisponibilidad();
    consultarAPILote();
    consultarAPIBarrios();
    consultarAPITipologias();
    getInversionistas();
  }, []);

  const actualizarPrecioLista = (event) => {
    const e = {
      target: {
        name: 'precioLista',
        value: event.floatValue
      }
    }
    actualizarState(e);
  }

  // Manejador de estado global
  const actualizarState = (e) => {
    setLote({
      ...lote,
      [e.target.name]: e.target.value,
    });
  };

  // Manejadores de los Selects
  const setBarrio = (e) => {
    setLote({
      ...lote,
      barrio: e.value,
    });
  };

  const setTipologia = (e) => {
    setLote({
      ...lote,
      tipologia: e.value,
    });
  };

  const setEstadoLote = (e) => {
    const loteCopy = { ...lote };
    console.log(e)
    loteCopy.estado = e;
    setLote(loteCopy);
  };

  const setMotivoDisponibilidad = (e) => {
    setLote({
      ...lote,
      motivoDisponibilidad: e.value,
    });
  };


  // Enviar lote a la API
  const enviarLote = async (e) => {
    e.preventDefault();

    try {
      const rta = await clienteAxios.put(`/lotes/${id}`, lote, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      history.push(`/lotes/${lote.barrio._id}`);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const spliceComentario = (comentario) => {
    const lineas = comentario.split('\n');
    return lineas;
  }

  const agregarComentario = async () => {
    if (!lote.comentarios) {
      lote.comentarios = [];
    }
    const loteCopy = { ...lote };
    loteCopy.comentarios.push(
      {
        comentario: nuevoComentario,
        fecha: Date.now(),
        usuario: userId,
      }
    );
    setLote(loteCopy);
    setNuevoComentario('');
    try {
      const rta = await clienteAxios.put(`/lotes/${id}`, lote, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      consultarAPILote();
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  const switchBlockLote = (e) => {
    e.preventDefault();
    if (lote.estado?.id === 0) {
      const estadoBloqueado = estadosLotes.find(estado => estado.value == 3);
      setLote({
        ...lote,
        motivoDisponibilidad: null,
        estado: estadoBloqueado
      });
    } else if (lote.estado?.id === 3) {
      const estadoDisponible = estadosLotes.find(estado => estado.value == 0);
      setLote({
        ...lote,
        motivoDisponibilidad: null,
        estado: estadoDisponible
      });
    }
  }

  const columns = [
    {
      name: "Fecha Alta",
      selector: (row) => moment(row.fechaAlta)
        .format("DD-MM-YYYY - HH:mm"),
      sortable: true,
    },
    {
      name: "Tipo",
      selector: (row) => row.tipo ?? '',
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (row) => row ? (row.cliente.razonSocial ? row.cliente.razonSocial : `${row.cliente?.nombre?.apellido ?? ''}, ${row.cliente?.nombre?.nombre ?? ''}`) : '',
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado?.estado,
      sortable: true,
    },
    {
      name: "Ver",
      button: true,
      cell: (row) => (
        <Link to={`/operaciones/${row._id}`}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
    },
  ];

  const data = operacionesRelacionadas;

  const handleLoteInversor = (e) => {
    const value = e.target.checked;
    setLote({
      ...lote,
      isInversor: value
    });
  }


  if (lote.loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Editar Lote</h3>
          <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
        </div>

        <div className="card-body">
          <form onSubmit={enviarLote}>
            <div className="row">
              <div className="col-md-6 form-group">
                <label>Desarrollo</label>
                <input
                  type="text"
                  className="form-control"
                  value={lote.barrio.nombre}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Estado</label>
                <Select
                  options={estadosLotes}
                  onChange={setEstadoLote}
                  defaultValue={{
                    value: lote.estado?._id,
                    label: lote.estado?.estado,
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-2 form-group">
                <label>Numero de Lote</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Numero de Lote"
                  name="numeroLote"
                  value={lote.numeroLote}
                  onChange={actualizarState}
                  disabled={lote.estado?.id !== 0}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>Número de Manzana</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Numero de manzana"
                  name="numeroManzana"
                  value={lote.numeroManzana}
                  onChange={actualizarState}
                  disabled={lote.estado?.id !== 0}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>Superficie</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Superficie"
                  name="superficie"
                  value={lote.superficie}
                  onChange={actualizarState}
                  disabled={lote.estado?.id !== 0}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Precio de Lista</label>
                <NumberFormat
                  className="form-control"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                  value={lote.precioLista}
                  onValueChange={actualizarPrecioLista}
                  disabled={lote.estado?.id !== 0}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Tipología</label>
                {lote.estado?.id !== 0 &&
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tipología"
                    name="tipologia"
                    value={lote.tipologia.tipo}
                    onChange={actualizarState}
                    disabled={lote.estado?.id !== 0}
                  />
                }
                {lote.estado?.id === 0 &&
                  <Select
                    disabled={lote.estado?.id !== 0}
                    options={tipologias}
                    onChange={setTipologia}
                    defaultValue={{
                      value: lote.tipologia._id,
                      label: lote.tipologia.tipo,
                    }}
                  />
                }
              </div>
              <div className="col-md-3 form-group">
                <label>Motivo Disponibilidad</label>
                <Select
                  disabled={lote.estado?.id !== 0}
                  options={getMotivosDisponibilidad()}
                  onChange={setMotivoDisponibilidad}
                  defaultValue={{
                    value: lote.motivoDisponibilidad?._id || '',
                    label: lote.motivoDisponibilidad?.nombre || '',
                  }}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Es Lote Inversor?</label>
                <input
                  type="checkbox"
                  className="form-control"
                  name="activo"
                  checked={lote?.isInversor}
                  onChange={handleLoteInversor}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <label>Inversionista:</label>
                <Select
                  isDisabled={!lote?.isInversor}
                  options={[{ value: undefined, label:`--- Ninguno ---` }, ...inversionistas.map(inversionista => ({ value: inversionista._id, label: inversionista.nombreCorto }))]}
                  onChange={(e) => {
                      setLote({
                        ...lote,
                        inversor: e.value
                      });
                    }
                  }
                  defaultValue={{
                    value: lote.inversor?._id,
                    label: lote.inversor?.nombreCorto
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                {lote && lote.estado && lote.estado?.id === 0 &&
                  <button onClick={switchBlockLote} className="btn btn-danger float-right">
                    Bloquear Lote
                  </button>
                }
                {lote && lote.estado && lote.estado?.id === 3 &&
                  <button onClick={switchBlockLote} className="btn btn-primary float-right">
                    Desbloquear Lote
                  </button>
                }
              </div>
            </div>


            <div className="row mt-4">
              <div className="col-md-12">
                <div className="card-header">
                  <h3 className="card-title">Comentarios:</h3>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                {/* The time line */}
                <div className="timeline">
                  {lote.comentarios?.length > 0 ? lote.comentarios.map((comentario, index) => {
                    return (
                      <div key={index}>
                        <i className="fas fa-envelope bg-blue" />
                        <div className="timeline-item">
                          <span className="time">
                            <i className="fas fa-clock" />{" "}
                            {moment(comentario.fecha)
                              //   .utcOffset(180)
                              .format("DD-MM-YYYY - HH:mm")}
                          </span>
                          <h3 className="timeline-header">
                            {`Usuario: ${comentario.usuario?.apellido || '< Sin Apellido >'}, ${comentario.usuario?.nombre || '< Sin Nombre >'}`} - {comentario.usuario?.inmobiliaria?.nombre || '< Sin Nombre Inmobiliaria>'}
                          </h3>
                          {spliceComentario(comentario.comentario).map((linea) => {
                            return <div className="timeline-body">{linea}</div>
                          })}
                        </div>
                      </div>
                    );
                  }) : ''}
                </div>
                <div className="modal-body col-md-12">
                  <p>Nuevo Comentario:</p>
                  <textarea
                    className="p-4 w-100"
                    name="comentario"
                    id=""
                    cols="100"
                    rows="3"
                    onChange={(e) => setNuevoComentario(e.target.value)}
                    value={nuevoComentario}
                  ></textarea>
                </div>
                <input className="btn btn-primary float-right" onClick={agregarComentario} type="button"
                  value="Enviar Comentario" />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div className="card-header">
                  <h3 className="card-title">Operaciones Relacionadas:</h3>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <DataTable
                  data={data}
                  columns={columns}
                />
              </div>
            </div>

            <div className="card-footer mt-5">
              <button type="submit" className="btn btn-primary float-right">
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default withRouter(EditarLote);
