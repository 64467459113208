import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';

export const ListadoCategorias = ({ history, ...props }) => {

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [categorias, setCategorias] = useState([]);

  const getFromAPI = async () => {
    try {
      const response = await clienteAxios.get("/api/documentacion/categorias", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategorias(response.data);
    } catch (error) {
      if (error.response?.status === 500) {
        // localStorage.clear();
        // history.push("/login");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    getFromAPI();
  }, []);

  console.log(categorias)

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-8">
              <h3 className="card-title mt-2">Documentación</h3>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary float-right" onClick={() => history.push('/documentacion/categorias/nuevo')}>Nuevo</button>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {categorias.map(categoria => (
                    <tr key={categoria._id}>
                      <td>{categoria.nombre}</td>
                      {/* <td>
                        <button className="btn btn-primary"
                          onClick={() => history.push(`/documentacion/editar/${documento._id}`)}
                        >Editar</button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </>
  )
}
