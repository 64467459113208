import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import GetDolarMercado from "../../../../../utils/GetDolarMercado";
import Spinner from '../../../../../layout/Spinner';

const formatterAr = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});



const PlanDeCuotas = ({ saldoFinanciar, isFinanciacionUnica = false, financiacion, deleteFinanciacion, setFinanciacion, index, valorDolarMercado, ...props }) => {

  const [cuotasOptions, setCuotasOptions] = useState([]);

  const obtenerFechaPrimerPago = () => {
    const fechaActual = new Date();

    const diaActual = fechaActual.getDate();
    const monthCorrection = diaActual <= 15 ? 1 : 2;
    let mesSiguiente = fechaActual.getMonth() + monthCorrection;

    // Obtener el año actual
    let añoActual = fechaActual.getFullYear();

    // Verificar si el mes siguiente supera el límite de los meses (de 0 a 11)
    if (mesSiguiente > 11) {
      mesSiguiente = 0; // Volver al primer mes del siguiente año
      añoActual++; // Aumentar el año en 1
    }

    // Establecer la fecha al día 10 del mes siguiente
    const fechaSiguiente = new Date(añoActual, mesSiguiente, 10);

    // Formatear la fecha en el formato "yyyy-MM-dd"
    var fechaFormateada = fechaSiguiente.toISOString().split('T')[0];
    return fechaFormateada;
  }

  const obtenerBaseCAC = () => {
    const fechaActual = new Date(); // Obtener la fecha actual
    const diaDelMes = fechaActual.getDate();

    // Determinar si el día de la fecha actual está entre 1 y 15
    if (diaDelMes >= 1 && diaDelMes <= 15) {
      // Restar 2 meses a la fecha actual
      fechaActual.setMonth(fechaActual.getMonth() - 2);
    } else {
      // Restar 1 mes a la fecha actual
      fechaActual.setMonth(fechaActual.getMonth() - 1);
    }

    // Obtener el año y el mes de la fecha resultante
    const año = fechaActual.getFullYear();
    const mes = fechaActual.getMonth() + 1;

    // Formatear el año y el mes en el formato "YYYY-MM"
    const mesFormateado = mes < 10 ? "0" + mes : mes;
    const fechaFormateada = año + "-" + mesFormateado;

    return fechaFormateada;
  };

  useEffect(() => {
    const cuotas = [];
    for (let i = 1; i <= 60; i++) {
      cuotas.push({
        label: i,
        value: i
      });
    }
    setCuotasOptions(cuotas);

    const defaultValues = {};

    if (!financiacion.cambioPropuesto) {
      defaultValues.cambioPropuesto = valorDolarMercado
    }
    if (!financiacion.fechaIndiceCac) {
      defaultValues.fechaIndiceCac = obtenerBaseCAC();
    }
    if (!financiacion.fechaPrimerPago) {
      defaultValues.fechaPrimerPago = obtenerFechaPrimerPago();
    }

    setFinanciacion(index, {
      ...financiacion,
      ...defaultValues
    });
  }, []);

  useEffect(() => {
    if (isFinanciacionUnica) {
      setFinanciacion(index, {
        ...financiacion,
        valorCuotaPropuesto: saldoFinanciar / financiacion.cantidadCuotas,
        valorCuotaPropuestoDolares: saldoFinanciar / financiacion.cantidadCuotas / valorDolarMercado,
      });
    }
  }, [isFinanciacionUnica]);

  const monedaFinanciado = [
    {
      label: 'Pesos',
      value: 'ars',
    }, {
      label: 'Dólares',
      value: 'usd'
    }
  ];

  const periodicidad = [
    {
      label: 'Mensual',
      value: 'Mensual'
    }, {
      label: 'Trimestral',
      value: 'Trimestral'
    }, {
      label: 'Semestral',
      value: 'Semestral'
    }, {
      label: 'Anual',
      value: 'Anual'
    },
  ]

  const actualizarState = (e) => {
    const { name, value } = e.target;

    setFinanciacion(index, {
      ...financiacion,
      [name]: value
    });

  }

  const actualizarStateSelect = (name, value) => {
    actualizarState({
      target: {
        name,
        value,
      }
    });
  }

  const setFechaIndiceCac = (fecha) => {
    setFinanciacion(index, {
      ...financiacion,
      fechaIndiceCac: fecha
    });
  }

  const setFechaPrimerPago = (fecha) => {
    setFinanciacion(index, {
      ...financiacion,
      fechaPrimerPago: fecha
    });
  }

  const updateCantidadCuotas = (e) => {
    if (isFinanciacionUnica) {
      setFinanciacion(index, {
        ...financiacion,
        cantidadCuotas: e.value,
        valorCuotaPropuesto: saldoFinanciar / e.value,
        valorCuotaPropuestoDolares: saldoFinanciar / e.value / financiacion.cambioPropuesto,
      });
    } else {
      actualizarStateSelect('cantidadCuotas', e.value)
    }
  }

  const setCambioPropuesto = (e) => {
    if (isFinanciacionUnica) {
      setFinanciacion(index, {
        ...financiacion,
        cambioPropuesto: e.floatValue,
        valorCuotaPropuesto: saldoFinanciar / financiacion.cantidadCuotas,
        valorCuotaPropuestoDolares: saldoFinanciar / financiacion.cantidadCuotas / e.floatValue,
      });
    } else {
      actualizarStateSelect('cambioPropuesto', e.floatValue)
    }
  }


  return (
    <>
      <div className="row">

        <div className='col-md-3 form-group'>
          <label>Moneda</label>
          <Select
            name='moneda'
            options={monedaFinanciado}
            value={financiacion.monedaSeleccionada ? monedaFinanciado.find(moneda => moneda.value === financiacion.monedaSeleccionada) : null}
            onChange={(e) => actualizarStateSelect('monedaSeleccionada', e.value)}
          />
        </div>

        {financiacion.monedaSeleccionada === 'usd' ?
          <div className='col-md-3 form-group'>
            <label>Cambio Propuesto</label>
            <NumberFormat
              className="form-control"
              thousandSeparator={"."}
              decimalSeparator={","}
              decimalScale={2}
              prefix={'$ '}
              onValueChange={setCambioPropuesto}
              value={financiacion.cambioPropuesto}
            />
          </div>
          : null}

        {financiacion.monedaSeleccionada === 'ars' ?
          <div className="col-md-3">
            <label>Mes base Índice CAC</label>
            <input
              type="month"
              className="form-control"
              name="fechaIndiceCac"
              onChange={((e) => setFechaIndiceCac(e.target.value))}
              value={financiacion.fechaIndiceCac}
            />
          </div>
          : null}

        <div className='col-md-3 form-group'>
          <label>Cuotas</label>
          <Select
            name='cuotas'
            options={cuotasOptions}
            value={cuotasOptions.find(cuota => parseInt(cuota.value, 10) === financiacion.cantidadCuotas)}
            onChange={updateCantidadCuotas}
          />
        </div>

        <div className='col-md-3 form-group'>
          <label>Periodicidad</label>
          <Select
            name='periodicidad'
            options={periodicidad}
            value={periodicidad.find(per => per.value === financiacion.periodicidad)}
            onChange={(e) => actualizarStateSelect('periodicidad', e.value)}
          />
        </div>

        <div className="form-group col-md-3">
          <label>Fecha del primer pago</label>
          <input
            type="date"
            className="form-control"
            name="fechaPrimerPago"
            onChange={(e) => setFechaPrimerPago(e.target.value)}
            value={financiacion.fechaPrimerPago}
          />
        </div>

        <div className='col-md-3 form-group'>
          <label>Valor de la Cuota</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            prefix={financiacion.monedaSeleccionada === 'ars' ? "$ " : "u$s "}
            value={financiacion.monedaSeleccionada === 'ars' ? financiacion.valorCuotaPropuesto : financiacion.valorCuotaPropuestoDolares}
            onValueChange={(e) => financiacion.monedaSeleccionada === 'ars' ? actualizarStateSelect('valorCuotaPropuesto', e.floatValue) : actualizarStateSelect('valorCuotaPropuestoDolares', e.floatValue)}
          />
        </div>

        <div className='col-md-3 form-group'>
        </div>
        <div className='col-md-3 form-group'>
          <button className="btn btn-danger mt-5" onClick={() => deleteFinanciacion(index)}>Eliminar</button>
        </div>

      </div>
    </>
  )
}

const SelectFinanciacionMultiple = ({ onClose, operacion, setOperacion, goBack, ...props }) => {
  const [valorDolarMercado, setValorDolarMercado] = useState(0);
  const [financiaciones, setFinanciaciones] = useState(operacion.formaDePago.financiaciones || [{}]);
  const [loadingDolarMercado, setLoadingDolarMercado] = useState(true);

  const saveFinanciacion = () => {
    onClose();
  }

  const addFinanciacion = () => {
    setFinanciaciones([...financiaciones, {}]);
  }

  const setFinanciacion = (index, financiacion) => {
    const financiacionesCopy = [...financiaciones];
    financiacionesCopy[index] = financiacion;
    setFinanciaciones(financiacionesCopy);
  }

  const deleteFinanciacion = (index) => {
    const financiacionesCopy = [...financiaciones];
    financiacionesCopy.splice(index, 1);
    setFinanciaciones(financiacionesCopy);
  }

  const obtainDolarMercado = async () => {
    const dolarApi = await GetDolarMercado.getDolar();
    const valorDolarMercado = dolarApi.data.compra;
    setValorDolarMercado(valorDolarMercado);
    setLoadingDolarMercado(false);
  }

  useEffect(() => {
    obtainDolarMercado();
  }, []);

  const completeFinanciaciones = (financiaciones = []) => {
    financiaciones.forEach((financiacion, index) => {
      // console.log(financiacion);
    });
  }

  const totalFinanciado = financiaciones.reduce((prev, curr) => {
    if (curr.monedaSeleccionada === 'ars') {
      return prev + curr.valorCuotaPropuesto * curr.cantidadCuotas;
    } else {
      return prev + curr.valorCuotaPropuestoDolares * curr.cantidadCuotas * curr.cambioPropuesto;
    }
  }, 0);




  useEffect(() => {
    const operacionCopy = { ...operacion };
    operacionCopy.formaDePago.financiaciones = financiaciones;
    completeFinanciaciones(financiaciones);
    setOperacion({
      ...operacionCopy
    });
  }, [financiaciones]);

  if (loadingDolarMercado) {
    return <Spinner />
  }


  return (
    <>

      {financiaciones.map((financiacion, index) =>
        <PlanDeCuotas saldoFinanciar={operacion.saldoFinanciar} isFinanciacionUnica={financiaciones.length === 1} valorDolarMercado={valorDolarMercado} key={index} index={index} operacion={operacion} financiacion={financiacion} setFinanciacion={setFinanciacion} deleteFinanciacion={deleteFinanciacion} />
      )}

      <div className="row">
        <div className="col-md-10">
          {financiaciones.length !== 1 ? <p>Saldo a financiar {formatterAr.format(operacion.saldoFinanciar - totalFinanciado)}</p> : null}
        </div>
        <div className="col-md-2 mb-3 mt-5">
          <button className="btn btn-primary" onClick={addFinanciacion}>Agregar Otro</button>
        </div>
      </div>


      <div className="mt-5">
        <div className="row d-flex justify-content-around card-footer">

          <div className="">
            <button className="btn btn-secondary" onClick={goBack}>Anterior</button>
          </div>

          <div className="">
            <button className="btn btn-primary" onClick={saveFinanciacion}>Siguiente</button>
          </div>

        </div>
      </div>
    </>
  )
}

export default withRouter(SelectFinanciacionMultiple);