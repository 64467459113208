import moment from 'moment';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import clienteAxios from '../../../../../../config/axios';
import { CRMContext } from '../../../../../../context/CRMContext';
import Swal from 'sweetalert2';


const moneyArFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const moneyUsFormatter = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const NombreRazonSocial = ({ cliente }) => {
  return (
    <>
      <div className="row">
        <label>Razón Social:</label>
      </div>
      <div className="row">
        <p>{cliente.razonSocial}</p>
      </div>
    </>
  )
}
const NombrePersonaFisica = ({ cliente }) => {
  const { nombre } = cliente;
  return (
    <>
      <div className="row">
        <label>Nombre Completo:</label>
      </div>
      <div className="row">
        <p>{nombre.apellido} {nombre.apellidoMaterno?.toUpperCase()}, {nombre.nombre}</p>
      </div>
    </>
  )
}
const DocumentosPersonaJuridica = ({ cliente }) => {
  const { documentos } = cliente;
  return (
    <>
      <div className="row">
        <label>CUIT:</label>
      </div>
      <div className="row">
        <p>{formatCuilCuit(documentos.cuitCuil)}</p>
      </div>
    </>
  )
}
const DocumentosPersonaFisica = ({ cliente }) => {
  const { documentos } = cliente;
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <label>DNI Tipo:</label>
          </div>
          <div className="row">
            <p>{documentos.dniTipo}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <label>DNI Número:</label>
          </div>
          <div className="row">
            <p>{new Intl.NumberFormat('es-Ar').format(documentos.dni)}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <label>CUIL:</label>
          </div>
          <div className="row">
            <p>{formatCuilCuit(documentos.cuitCuil)}</p>
          </div>
        </div>
      </div>
    </>
  )
}

const LotesSeleccionados = ({ lotesSeleccionados }) => {

  const columns = [
    {
      name: "Desarrollo",
      selector: (row) => row.barrio,
      sortable: true,
      width: 'auto',
    },
    {
      name: "Manzana",
      selector: (row) => row.restoInfo.numeroManzana,
      sortable: true,
      width: 'auto',
    },
    {
      name: "Número de Lote",
      selector: (row) => row.restoInfo.numeroLote,
      sortable: true,
      width: 'auto',
    },
    {
      name: "Superficie",
      selector: (row) => row.restoInfo.superficie,
      sortable: true,
      width: 'auto',
    },
    {
      name: "Tipología",
      selector: (row) => row.restoInfo.tipologia.tipo,
      sortable: true,
      width: 'auto',
    },
    {
      name: "Precio de Lista",
      // TODO -> Reforma precio lotes en dólares
      selector: (row) => moneyUsFormatter.format(row.restoInfo.precioLista),
      sortable: true,
      width: 'auto',
      style: {
        textAlign: 'center'
      }
    }
  ];

  return (
    <div className="form-group">
      <div className="row">
        <label>Lotes Seleccionados:</label>
        <DataTable columns={columns} data={lotesSeleccionados} responsive={true} />
      </div>
    </div>
  )
}

const FormaDePago = ({ formaDePago, saldoAFinanciar }) => {
  const { anticipo, financiaciones, entregas } = formaDePago;
  moment.locale('es-ar');
  return (
    <>
      {anticipo.lotes && anticipo.lotes.length > 0 &&
        <>
          <label>{anticipo.lotes.length > 1 ? 'Lotes:' : 'Lote:'}</label>
          {anticipo.lotes.map((lote, index) => {
            return <p
              key={index}>{`Entrega del lote ${lote.lote} con un valor de referencia de ${moneyUsFormatter.format(lote.valores.dolaresPropuesto)}`}</p>
          })}
        </>
      }
      {anticipo.efectivo && anticipo.efectivo.length > 0 && anticipo.efectivo[0].monto &&
        <>
          <label>Efectivo:</label>
          {anticipo.efectivo.map((eft, index) => {
            const cambio = eft.cambioSugerido ? eft.cambioSugerido : 1;
            const monto = eft.moneda === 'ars' ? moneyArFormatter.format(eft.monto) : moneyUsFormatter.format(eft.monto);

            let mensaje = `La entrega de ${monto} en efectivo`;
            if (eft.moneda === 'usd') {
              mensaje += ` a un valor de cambio de referencia de u$s 1 = $ ${cambio}.`;
            } else {
              mensaje += '.';
            }

            return <p key={index}>{mensaje}</p>
          })}
        </>
      }
      {anticipo.documentos && anticipo.documentos.length > 0 && anticipo.documentos[0].monto &&
        <>
          <label>Documentos:</label>
          {anticipo.documentos.map((documento, index) => {
            const cambio = documento.cambioSugerido ? documento.cambioSugerido : 1;
            const monto = documento.moneda === 'ars' ? moneyArFormatter.format(documento.monto) : moneyUsFormatter.format(documento.monto);
            const tipo = documento.type;
            const fechaDePago = moment(documento.fechaPago)
              .utcOffset(180)
              .format("DD-MM-YYYY");

            let mensaje = `La entrega de un ${tipo} por un valor estimado en ${monto} con fecha de pago el día ${fechaDePago}.`;
            if (documento.moneda === 'usd') {
              mensaje += ` a un valor de cambio de referencia de u$s 1 = $ ${cambio}`;
            }

            return <p key={index}>{mensaje}</p>
          })}
        </>
      }
      {anticipo.vehiculo &&
        <>
          <label>Vehículo:</label>
          <p>{`La entrega de un vehículo tipo: ${anticipo.vehiculo.vehiculo.type}, marca: ${anticipo.vehiculo.vehiculo.brand}, modelo: ${anticipo.vehiculo.vehiculo.model}, versión: ${anticipo.vehiculo.vehiculo.version}, año: ${anticipo.vehiculo.vehiculo.year}, con un valor de referencia de ${moneyArFormatter.format(anticipo.vehiculo.vehiculo.valorSugerido)}`}</p>
        </>
      }
      {anticipo.inmueble && anticipo.inmueble.valores.pesosPropuesto &&
        <>
          <label>Inmueble:</label>
          <p>{`La entrega de un ${anticipo.inmueble.type} ${anticipo.inmueble.dormitorios ? `con ${anticipo.inmueble.dormitorios} dormitorios, ` : ''}
          ${anticipo.inmueble.ambientes ? ` ${anticipo.inmueble.ambientes} ambientes, ` : ''}
          ${anticipo.inmueble.banos ? ` ${anticipo.inmueble.banos} baños, ` : ''}
          ${anticipo.inmueble.garage ? ` ${anticipo.inmueble.garage} cocheras, ` : ''}
          ${anticipo.inmueble.condicion ? `en condición ${anticipo.inmueble.condicion}, ` : ''}
          con un precio propuesto de: ${anticipo.inmueble.monedaPrecioSugerido === 'ars' ? moneyArFormatter.format(anticipo.inmueble.valores.pesosPropuesto) : moneyUsFormatter.format(anticipo.inmueble.valores.dolaresPropuesto)}
          ${anticipo.inmueble.monedaPrecioSugerido === 'usd' ? `a un valor de cambio de referencia de u$s 1 = $  ${anticipo.inmueble.valores.cambioPropuesto}.` : '.'}
          `}</p>
        </>
      }
      {entregas &&
        entregas.map(entrega => {
          let entregaTexto = '';
          if (entrega.tipo = 'Canje') {
            entregaTexto = `La entrega del canje tipo ${entrega.referencia.tipo}, ${entrega.referencia.descripcion}, por un valor en ${entrega.referencia.moneda} de `;
            entregaTexto += entrega.referencia.moneda === 'dolares' ? moneyUsFormatter.format(entrega.referencia.valores.dolaresPropuesto) : moneyArFormatter.format(entrega.referencia.valores.pesosPropuesto);
          }
          return <>
            <label>Canje:</label>
            <p>{entregaTexto}</p>
          </>
        })

      }
      {financiaciones && saldoAFinanciar > 0 &&
        <label>Financiaciones:</label>
      }
      {financiaciones && saldoAFinanciar > 0 &&
        financiaciones.map((financiacion, index) => {
          return <div key={index}>
            <label>{financiacion.monedaSeleccionada === 'ars' ? 'Cuotas ajustadas por CAC' : 'Financiación'}:</label>
            {financiacion &&
              <>
                <p>{`En ${financiacion.cantidadCuotas} cuotas de un valor de ${financiacion.monedaSeleccionada === 'ars' ? moneyArFormatter.format(financiacion.valorCuotaPropuesto) : moneyUsFormatter.format(financiacion.valorCuotaPropuestoDolares)} cada una, con una periodicidad ${financiacion.periodicidad}.
              La fecha del primer pago es el día ${moment(financiacion.fechaPrimerPago).utcOffset(180).format("DD-MM-YYYY")}
              ${financiacion.monedaSeleccionada === 'ars' ? `y el mes de base para el cálculo del índice CAC es el ${moment(financiacion.fechaIndiceCac).utcOffset(180).format("MM-YYYY")}` : ''}.
            `}</p>
              </>
            }
          </div>
        })
      }
    </>
  )
}

const Domicilio = ({ cliente }) => {

  const { domicilio } = cliente;
  if (!domicilio) {
    return (
      <></>
    )
  }
  const { altura, calle, cp, municipio, pisoDepto, provincia } = domicilio;

  return (
    <>
      <div className="row mb-1">
        <label>Domicilio: </label>
      </div>
      <div className="row">
        {provincia &&
          <div className="col-md-4">
            <div className="row">
              <label>Provincia:</label>
            </div>
            <div className="row">
              <p>{provincia}</p>
            </div>
          </div>
        }
        {municipio &&
          <div className="col-md-4">
            <div className="row">
              <label>Municipio:</label>
            </div>
            <div className="row">
              <p>{municipio}</p>
            </div>
          </div>
        }
        {cp &&
          <div className="col-md-4">
            <div className="row">
              <label>CP:</label>
            </div>
            <div className="row">
              <p>{cp}</p>
            </div>
          </div>
        }
      </div>
      <div className="row">
        {calle &&
          <div className="col-md-4">
            <div className="row">
              <label>Calle:</label>
            </div>
            <div className="row">
              <p>{calle}</p>
            </div>
          </div>
        }
        {altura &&
          <div className="col-md-4">
            <div className="row">
              <label>Altura:</label>
            </div>
            <div className="row">
              <p>{altura}</p>
            </div>
          </div>
        }
        {pisoDepto &&
          <div className="col-md-4">
            <div className="row">
              <label>Piso / Depto:</label>
            </div>
            <div className="row">
              <p>{pisoDepto}</p>
            </div>
          </div>
        }
      </div>
    </>
  )
}

const formatCuilCuit = (original) => {
  if (!original) {
    return '';
  }
  if (!isNaN(original)) {
    original = original.toString();
  }
  const part1 = original.slice(0, 2);
  const part2 = original.slice(2, 10);
  const part3 = original.slice(10);
  return `${part1}-${part2}-${part3}`;
}


const ResumenOperacion = ({ operacion, bloqueoId, setOperacion, ...props }) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const [errores, setErrores] = useState([]);
  let token = auth.token || localStorage.getItem("token");
  const { cliente, lote: lotesSeleccionados, formaDePago } = operacion;

  const isPersonaFisica = (cliente) => {
    return !!cliente.nombre;
  }

  const isPersonaJuridica = (cliente) => {
    return !!cliente.razonSocial;
  }

  const getNombre = (cliente) => {
    if (isPersonaJuridica(cliente)) {
      return <NombreRazonSocial cliente={cliente} />
    } else if (isPersonaFisica(cliente)) {
      return <NombrePersonaFisica cliente={cliente} />
    }
  }

  const getDocumentos = (cliente) => {
    if (isPersonaJuridica(cliente)) {
      return <DocumentosPersonaJuridica cliente={cliente} />
    } else if (isPersonaFisica(cliente)) {
      return <DocumentosPersonaFisica cliente={cliente} />
    }
  }
  const guardarOperacion = async () => {


    try {

      if (bloqueoId) {
        await clienteAxios.delete(
          `/bloqueos/undo/${bloqueoId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOperacion({
          ...operacion,
          bloqueoId
        });
      }

      const response = await clienteAxios.post("/operaciones", operacion, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 && response.data.status === 'ok') {
        const id = response.data.operacionId;
        Swal.fire("¡Correcto!", "Operacion generada correctamente", "success");
        props.history.push(`/operaciones/${response.data.operacionId}`);
      }

    } catch (error) {
      if (error.response?.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } else if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const checkFinanciaciones = () => {
    const { financiaciones } = operacion.formaDePago;
    if (financiaciones) {
      return financiaciones.length > 0 && financiaciones.every(f => f.cantidadCuotas > 0 && f.valorCuotaPropuesto > 0);
    }
    return false;
  }


  return (
    <>
      {(!operacion.cliente || !operacion.firmantes || operacion.firmantes.length === 0) &&
        <>
          <div className="card-header">
            <h3 className="card-title text-danger">Errores:</h3>
          </div>
          <div className="card-body">
            <ul>
              {!operacion.cliente &&
                <li><p className='text-danger'>El cliente no fue seleccionado</p></li>
              }
              {(!operacion.firmantes || operacion.firmantes.length === 0) &&
                <li><p className='text-danger'>El / los firmante/s no fue/ron guardados</p></li>
              }
              {!checkFinanciaciones() &&
                <li><p className='text-danger'>Debe seleccionar la moneda para el financiado.</p></li>
              }
            </ul>
          </div>
        </>
      }
      <div className="card-header">
        <h3 className="card-title">Datos del Cliente</h3>
      </div>
      {cliente &&
        <div className="card-body">

          <div className="mb-2">
            {getNombre(cliente)}
          </div>
          <div className="mb-2">
            {getDocumentos(cliente)}
          </div>
          <div className="mb-2">
            <Domicilio
              cliente={cliente}
            />
          </div>


          <label className='col-md-12'>Datos de Contacto</label>

          <div className="row">
            {cliente.telefonoMovil ? (
              <div className='col-md-6'>
                <label>Teléfono Móvil</label>
                <p>{cliente.telefonoMovil}</p>
              </div>
            ) : null}
            {cliente.telefonoFijo ? (
              <div className='col-md-6'>
                <label>Teléfono Fijo (Sin 0)</label>
                <p>{cliente.telefonoFijo}</p>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-12">
              <label>Estado Civil</label>
              <p>{cliente.estadoCivil?.nombre}</p>
            </div>
          </div>
        </div>
      }
      <div className="card-header">
        <h3 className="card-title">Lotes Seleccionados</h3>
      </div>
      <div className="card-body">
        <LotesSeleccionados lotesSeleccionados={lotesSeleccionados} />
      </div>
      <div className="card-header">
        <h3 className="card-title">Forma de Pago</h3>
      </div>
      <div className="card-body">
        <FormaDePago formaDePago={formaDePago} saldoAFinanciar={operacion.saldoFinanciar} />
      </div>
      <div className="mt-5">
        <div className="row d-flex justify-content-center card-footer">
          <div className="">
            <button className="btn btn-success" onClick={guardarOperacion}>Enviar Propuesta</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ResumenOperacion);