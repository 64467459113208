import moment from 'moment';
import React, {useState, useEffect, useContext} from 'react';
import NumberFormat from 'react-number-format';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';
import GetDolarMercado from "../../../../../utils/GetDolarMercado";

const SelectFinanciacion = ({onClose, operacion, setOperacion, goBack, ...props}) => {

  const monedaFinanciado = [
    {
      label: 'Pesos',
      value: 'ars',
    }, {
      label: 'Dólares',
      value: 'usd'
    }
  ]


  const periodicidad = [
    {
      label: 'Mensual',
      value: 'Mensual'
    }, {
      label: 'Trimestral',
      value: 'Trimestral'
    }, {
      label: 'Semestral',
      value: 'Semestral'
    }, {
      label: 'Anual',
      value: 'Anual'
    },
  ]

  const checkDisabledByRol = () => {
    return false; // Se habilita por solicitud de Juan para todos los roles 25/4/24
    const rolId = localStorage.getItem('rolId');
    return rolId != 4;
  }

  const [valorDolarMercado, setValorDolarMercado] = useState(0);
  const [cuotasOptions, setCuotasOptions] = useState([]);
  const financiacion = operacion.formaDePago.financiacion;

  const obtenerFechaPrimerPago = () => {
    const fechaActual = new Date();

    const diaActual = fechaActual.getDate();
    const monthCorrection = diaActual <= 15 ? 1 : 2;
    let mesSiguiente = fechaActual.getMonth() + monthCorrection;

    // Obtener el año actual
    let añoActual = fechaActual.getFullYear();

    // Verificar si el mes siguiente supera el límite de los meses (de 0 a 11)
    if (mesSiguiente > 11) {
      mesSiguiente = 0; // Volver al primer mes del siguiente año
      añoActual++; // Aumentar el año en 1
    }

    // Establecer la fecha al día 10 del mes siguiente
    const fechaSiguiente = new Date(añoActual, mesSiguiente, 10);

    // Formatear la fecha en el formato "yyyy-MM-dd"
    var fechaFormateada = fechaSiguiente.toISOString().split('T')[0];
    return fechaFormateada;
  }

  const obtenerBaseCAC = () => {
    const fechaActual = new Date(); // Obtener la fecha actual
    const diaDelMes = fechaActual.getDate();

    // Determinar si el día de la fecha actual está entre 1 y 15
    if (diaDelMes >= 1 && diaDelMes <= 15) {
      // Restar 2 meses a la fecha actual
      fechaActual.setMonth(fechaActual.getMonth() - 2);
    } else {
      // Restar 1 mes a la fecha actual
      fechaActual.setMonth(fechaActual.getMonth() - 1);
    }

    // Obtener el año y el mes de la fecha resultante
    const año = fechaActual.getFullYear();
    const mes = fechaActual.getMonth() + 1;

    // Formatear el año y el mes en el formato "YYYY-MM"
    const mesFormateado = mes < 10 ? "0" + mes : mes;
    const fechaFormateada = año + "-" + mesFormateado;

    return fechaFormateada;
  };

  const actualizarState = (e) => {
    const {name, value} = e.target;
    const operacionCopy = {...operacion};
    operacionCopy.formaDePago.financiacion[name] = value;
    if (e.name === 'cantidadCuotas') {
      operacionCopy.formaDePago.financiacion['valorCuotaPropuesto'] = operacionCopy.saldoFinanciar / parseInt(value, 10);
    }

    setOperacion({
      ...operacionCopy
    });
  }

  const actualizarStateSelect = (name, value) => {
    actualizarState({
      target: {
        name,
        value,
      }
    });
  }


  useEffect(async () => {
    const dolarApi = await GetDolarMercado.getDolar();
    const valorDolarMercado = dolarApi.data.compra;
    setValorDolarMercado(valorDolarMercado);
    const cuotas = [];
    if (operacion.formaDePago.financiacion) {
      const operacionCopy = {...operacion};
      const financiacion = operacionCopy.formaDePago.financiacion;
      if (!financiacion.cambioPropuesto) {
        financiacion.cambioPropuesto = valorDolarMercado;
      }
      if (!financiacion.valorCuotaPropuestoDolares) {
        financiacion.valorCuotaPropuestoDolares = operacionCopy.saldoFinanciar / valorDolarMercado;
      }
      if (!financiacion.valorCuotaPropuesto) {
        financiacion.valorCuotaPropuesto = operacionCopy.saldoFinanciar;
      }
      financiacion.fechaPrimerPago = obtenerFechaPrimerPago();
      financiacion.fechaIndiceCac = obtenerBaseCAC();
      setOperacion({
        ...operacionCopy
      });
    }
    for (let i = 1; i <= 60; i++) {
      cuotas.push({
        label: i,
        value: i
      });
    }
    setCuotasOptions(cuotas);
  }, []);

  useEffect(() => {
    const operacionCopy = {...operacion};
    const financiacionLocal = operacionCopy.formaDePago.financiacion;
    financiacionLocal.valorCuotaPropuesto = operacionCopy.saldoFinanciar / financiacionLocal.cantidadCuotas;
    financiacionLocal.valorCuotaPropuestoDolares = operacionCopy.saldoFinanciar / financiacionLocal.cantidadCuotas / financiacionLocal.cambioPropuesto;
    setOperacion(operacionCopy);
  }, [financiacion.cantidadCuotas]);


  const saveFinanciacion = () => {
    onClose();
  }

  const setFechaIndiceCac = (fecha) => {
    if (operacion.formaDePago.financiacion) {
      const operacionCopy = {...operacion};
      operacionCopy.formaDePago.financiacion.fechaIndiceCac = fecha;
      setOperacion({
        ...operacionCopy
      });
    }
  }

  const setFechaPrimerPago = (fecha) => {
    if (operacion.formaDePago.financiacion) {
      const operacionCopy = {...operacion};
      operacionCopy.formaDePago.financiacion.fechaPrimerPago = fecha;
      setOperacion({
        ...operacionCopy
      });
    }
  }


  return (
    <>

      <div className="row">
        <div className='col-md-3 form-group'>
          <label>Monto Solicitado</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            prefix={financiacion.monedaSeleccionada === 'ars' ? "$ " : 'u$s '}
            value={financiacion.monedaSeleccionada === 'ars' ? operacion.saldoFinanciar : operacion.saldoFinanciar / financiacion.cambioPropuesto}
            disabled
          />
        </div>
        <div className='col-md-3 form-group'>
          <label>Porcentaje a Financiar</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            suffix={"%"}
            decimalScale={2}
            value={operacion.saldoFinanciar / operacion.precioVentaOperacion * 100}
            disabled
          />
        </div>
        <div className='col-md-3 form-group'>
          <label>Moneda del Financiado</label>
          <Select
            name='moneda'
            options={monedaFinanciado}
            value={financiacion.monedaSeleccionada ? monedaFinanciado.find(moneda => moneda.value === financiacion.monedaSeleccionada) : null}
            onChange={(e) => actualizarStateSelect('monedaSeleccionada', e.value)}
          />
        </div>
        {financiacion.monedaSeleccionada === 'usd' ?
          <div className='col-md-3 form-group'>
            <label>Cambio Propuesto</label>
            <NumberFormat
              className="form-control"
              thousandSeparator={"."}
              decimalSeparator={","}
              decimalScale={2}
              prefix={'$ '}
              onValueChange={(e) => actualizarStateSelect('cambioPropuesto', e.floatValue)}
              value={financiacion.cambioPropuesto}
            />
          </div>
          : null}
      </div>

      <div className="row">

        <div className='col-md-3 form-group'>
          <label>Cuotas</label>
          <Select
            name='cuotas'
            options={cuotasOptions}
            value={cuotasOptions.find(cuota => parseInt(cuota.value, 10) === financiacion.cantidadCuotas)}
            onChange={(e) => actualizarStateSelect('cantidadCuotas', e.value)}
          />
        </div>

        <div className='col-md-3 form-group'>
          <label>Valor de la Cuota</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            prefix={financiacion.monedaSeleccionada === 'ars' ? "$ " : "u$s "}
            value={financiacion.monedaSeleccionada === 'ars' ? financiacion.valorCuotaPropuesto : financiacion.valorCuotaPropuestoDolares}
            disabled
          />
        </div>

        <div className='col-md-3 form-group'>
          <label>Periodicidad</label>
          <Select
            name='periodicidad'
            options={periodicidad}
            value={periodicidad.find(per => per.value === financiacion.periodicidad)}
            onChange={(e) => actualizarStateSelect('periodicidad', e.value)}
          />
        </div>
      </div>
      <div className="row">

        <div className="form-group col-md-3">
          <label>Fecha del primer pago</label>
          <input
            type="date"
            className="form-control"
            name="fechaPrimerPago"
            onChange={(e) => setFechaPrimerPago(e.target.value)}
            disabled={checkDisabledByRol()}
            value={operacion.formaDePago.financiacion.fechaPrimerPago}
          />
        </div>
        {financiacion.monedaSeleccionada === 'ars' ?
          <div className="col-md-4">
            <label>Mes para base del Índice CAC</label>
            <input
              type="month"
              className="form-control"
              name="fechaIndiceCac"
              onChange={((e) => setFechaIndiceCac(e.target.value))}
              value={operacion.formaDePago.financiacion.fechaIndiceCac}
              disabled={checkDisabledByRol()}
            />
          </div>
          : null}
      </div>

      <div className="mt-5">
        <div className="row d-flex justify-content-around card-footer">

          <div className="">
            <button className="btn btn-secondary" onClick={goBack}>Anterior</button>
          </div>

          <div className="">
            <button className="btn btn-primary" onClick={saveFinanciacion}>Siguiente</button>
          </div>

        </div>
      </div>
    </>
  )
}

export default withRouter(SelectFinanciacion);