import React from 'react';
import PrecioBoleto from "./PrecioBoleto";
import EntregasBoleto from "./EntregasBoleto";
import FinanciadoBoleto from "./FinanciadoBoleto";
import CuadroResumenBoleto from "./CuadroResumenBoleto";

function FormaPagoBoleto(props) {

  const {operacion, setOperacion, setCaracteristicasBoleto} = props;


  return (
    <div>
      <PrecioBoleto setCaracteristicasBoleto={setCaracteristicasBoleto} operacion={operacion} setOperacion={setOperacion}/>
      <EntregasBoleto setCaracteristicasBoleto={setCaracteristicasBoleto} operacion={operacion} setOperacion={setOperacion} />
      <FinanciadoBoleto setCaracteristicasBoleto={setCaracteristicasBoleto} operacion={operacion} setOperacion={setOperacion} />
      {/*<CuadroResumenBoleto setCaracteristicasBoleto={setCaracteristicasBoleto} operacion={operacion} setOperacion={setOperacion} />*/}
    </div>
  );
}

export default FormaPagoBoleto;