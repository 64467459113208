import React, { useContext, useEffect, useState } from 'react';
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';
import moment from 'moment';

export const ListadoDocumentacion = ({ history, ...props }) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [documentacion, setDocumentacion] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState('');
  const [minFecha, setMinFecha] = useState('');
  const [maxFecha, setMaxFecha] = useState('');

  const getCategorias = async () => {
    try {
      const response = await clienteAxios.get("/api/documentacion/categorias", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategorias(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFromAPI = async () => {
    try {
      const response = await clienteAxios.get("/api/documentacion", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          categoria: selectedCategoria || undefined,
          minFecha: minFecha || undefined,
          maxFecha: maxFecha || undefined,
        }
      });
      setDocumentacion(response.data);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const obtenerArchivo = async (nombreArchivo) => {
    try {
      const rta = await clienteAxios
        .get(`/archivosAdjuntos/${nombreArchivo}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${nombreArchivo}`);
            document.body.appendChild(link);
            link.click();
          }
        });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    getCategorias();
    getFromAPI();
  }, [selectedCategoria, minFecha, maxFecha]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-8">
              <h3 className="card-title mt-2">Documentación</h3>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary float-right" onClick={() => history.push('/documentacion/nuevo')}>Nuevo</button>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-4">
              <select
                className="form-control"
                value={selectedCategoria}
                onChange={(e) => setSelectedCategoria(e.target.value)}
              >
                <option value="">Seleccionar Categoria</option>
                {categorias.map(categoria => (
                  <option key={categoria._id} value={categoria._id}>
                    {categoria.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                value={minFecha}
                onChange={(e) => setMinFecha(e.target.value)}
                placeholder="Min Fecha"
              />
            </div>
            <div className="col-md-3">
              <input
                type="date"
                className="form-control"
                value={maxFecha}
                onChange={(e) => setMaxFecha(e.target.value)}
                placeholder="Max Fecha"
              />
            </div>
            <div className="col-md-2">
              <button className="btn btn-secondary" onClick={getFromAPI}>Aplicar Filtros</button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Fecha Alta</th>
                    <th>Fecha Documento</th>
                    <th>Categoria</th>
                    <th>Documentos</th>
                  </tr>
                </thead>
                <tbody>
                  {documentacion.map(documento => (
                    <tr key={documento._id}>
                      <td>{moment(documento.fechaAlta).utcOffset(180).format("DD-MM-YYYY")}</td>
                      <td>{moment(documento.fechaDocumentos).utcOffset(180).format("DD-MM-YYYY")}</td>
                      <td>{documento.categoria.nombre}</td>
                      <td>{
                        documento.archivosAdjuntos.map((archivo, index) => (
                          <p key={index}>{archivo.nombre}   <span onClick={() => obtenerArchivo(archivo.nombreArchivo)} ><i className="fas fa-download"></i></span></p>
                        ))
                      }</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </>
  );
};
