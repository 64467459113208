import { React, useContext, useState } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import { SelectTipoDni } from "./SelectTipoDni";
import { SelectNacionalidad } from "./SelectNacionalidad";
import { SelectEstadoCivil } from "./SelectEstadoCivil";
import { SelectInmobiliaria } from "./SelectInmobiliaria";
import DatosConyuge from "./DatosConyuge";
import { SelectSexo } from "./SelectSexo";
import SelectNupcias from "./SelectNupcias";
import { CRMContext } from "../../../context/CRMContext";
import SelectProvinciaMunicipio from "../../../shared/SelectProvinciaMunicipio";
import { GetPersonaByCuil } from "../../utils/getPersonaByCuil";
import Spinner from "../../layout/Spinner";

export const FormularioCliente = ({
  cliente,
  actualizarState,
  actualizarStateV2,
  showSearchBar = false,
  isNew = false
}) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const checkActivo = e => {
    e.target.value = e.target.value == 'on' ? true : false;
    actualizarState(e);
  }

  const updateState = (data) => {
    actualizarStateV2(data);
  }

  const clearCliente = (client) => {
    Object.keys(client).forEach(key => {
      if (key === 'cuitCuil' || key === 'fechaAlta') {
        return;
      }
      client = {
        ...client,
        [key]: null
      }
    });
    return client;
  }

  const clearClienteParameter = () => {
    actualizarStateV2(clearCliente(cliente));
  }

  const getClientByCuil = (clientApi) => {
    let state = clearCliente({ ...cliente });
    if (clientApi) {
      const { nombre, documentos, domicilio, fechaNacimiento, razonSocial, fechaContratoSocial } = clientApi;
      if (nombre) {
        Object.keys(nombre).forEach(key => {
          state = {
            ...state,
            [key]: nombre[key]
          };
        });
      }
      if (razonSocial) {
        state = {
          ...state,
          razonSocial
        }
      }
      Object.keys(documentos).forEach(key => {
        state = {
          ...state,
          [key]: documentos[key]
        };
      });
      Object.keys(domicilio).forEach(key => {
        state = {
          ...state,
          [key]: domicilio[key]
        };
      });
      if (fechaNacimiento) {
        state = {
          ...state,
          fechaNacimiento,
        }
      } else {
        state = {
          ...state,
          fechaContratoSocial
        }
      }
      ;
      state = {
        ...state,
        createdFromAfip: true,
      }
      actualizarStateV2(state);
    }
  }

  const checkDisabledByRol = () => {
    const disabledEditionRol = ['1', '2'];
    const rolId = localStorage.getItem('rolId');
    return disabledEditionRol.includes(rolId);
  }

  return (
    <>
      {showSearchBar &&
        <div className="row">
          <div className="col-md-4 form-group">
            <GetPersonaByCuil
              clearFunction={clearClienteParameter}
              setterFunction={getClientByCuil}
              showSpinner={setShowSpinner}
              label={"Buscar cliente por CUIL / CUIT:"}
            />
          </div>
        </div>
      }
      {showSpinner &&
        <Spinner />
      }
      {(cliente.nombre || cliente.apellido) &&
        <div className="row">
          <div className="col-md-4 form-group">
            <label>Nombre *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre"
              name="nombre"
              value={cliente?.nombre}
              required
              disabled={checkDisabledByRol()}
              onChange={actualizarState}
            />
          </div>


          <div className="col-md-4 form-group">
            <label>Apellido *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Apellido"
              name="apellido"
              value={cliente?.apellido}
              required
              disabled={checkDisabledByRol()}
              onChange={actualizarState}
            />
          </div>

          <div className="col-md-4 form-group">
            <label>Apellido Materno</label>
            <input
              type="text"
              className="form-control"
              placeholder="Apellido Materno"
              name="apellidoMaterno"
              onChange={actualizarState}
              value={cliente?.apellidoMaterno}
            />
          </div>
        </div>
      }
      {cliente.razonSocial &&
        <div className="row">
          <div className="col-md-12 form-group">
            <label>Razón Social *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Razón Social"
              name="nombre"
              value={cliente?.razonSocial}
              disabled={checkDisabledByRol()}
              onChange={actualizarState}
            />
          </div>
        </div>
      }
      <div className="row">

        {cliente.tipoDni &&
          <SelectTipoDni
            tipoDni={cliente.tipoDni}
            disabled={checkDisabledByRol()}
          />
        }

        {cliente.dni &&
          <div className="col-md-3 form-group">
            <label>Documento *</label>
            <NumberFormat
              className="form-control"
              name="dni"
              value={cliente?.dni}
              placeholder="20.123.456"
              format="##.###.###"
              mask="_"
              required
              disabled={checkDisabledByRol()}
              onChange={actualizarState}
            />
          </div>
        }

        {(cliente.nombre || cliente.apellido) &&
          <SelectNacionalidad
            actualizarState={actualizarState}
            cliente={cliente}
            token={token}
            required
          />
        }

        <div className="col-md-3 form-group">
          <label>CUIT / CUIL</label>
          <NumberFormat
            className="form-control"
            name="cuitCuil"
            value={cliente?.cuitCuil}
            placeholder="20-12345678-9"
            format="##-########-#"
            mask="_"
            disabled={true}
          />
        </div> 
      </div>

      {cliente.calle &&
        <>
          <div className="form-group">
            <label>Domicilio</label>
          </div>

          <div className="row">
            <SelectProvinciaMunicipio
              provincia={cliente.provincia}
              municipio={cliente.municipio}
              disabled={checkDisabledByRol()}
              setState={actualizarState}
            />
            <div className="col-md-2 form-group">
              <label>Código Postal</label>
              <input
                type="text"
                className="form-control"
                placeholder="CP"
                name="cp"
                value={cliente?.cp}
                required
                disabled={checkDisabledByRol()}
                onChange={actualizarState}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>Calle *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Calle"
                name="calle"
                value={cliente?.calle}
                required
                disabled={checkDisabledByRol()}
                onChange={actualizarState}
              />
            </div>

            <div className="col-md-3 form-group">
              <label>Altura *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Altura"
                name="altura"
                value={cliente?.altura}
                required
                disabled={checkDisabledByRol()}
                onChange={actualizarState}
              />
            </div>
            <div className="col-md-3 form-group">
              <label>Piso / Depto</label>
              <input
                type="text"
                className="form-control"
                placeholder="Piso / Depto"
                name="pisoDepto"
                value={cliente?.pisoDepto}
                disabled={checkDisabledByRol()}
                onChange={actualizarState}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>Teléfono Móvil * (Sin 0 y sin 15)</label>
              <NumberFormat
                className="form-control"
                placeholder="__________"
                name="telefonoMovil"
                format="##########"
                mask="_"
                onChange={actualizarState}
                value={cliente?.telefonoMovil || ''}
                required
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Teléfono Fijo (Sin 0)</label>
              <NumberFormat
                className="form-control"
                name="telefonoFijo"
                format="##########"
                placeholder="__________"
                mask="_"
                onChange={actualizarState}
                value={cliente?.telefonoFijo || ''}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 form-group">
              <label>Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                onChange={actualizarState}
                value={cliente?.email}
                required
              />
            </div>
            {cliente.fechaNacimiento &&
              <div className="col-md-4 form-group">
                <label>Fecha de Nacimiento *</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Fecha de Nacimiento"
                  name="fechaNacimiento"
                  value={moment(cliente?.fechaNacimiento)
                    .utcOffset(180)
                    .format("YYYY-MM-DD")}
                  required
                  disabled={checkDisabledByRol()}
                  onChange={actualizarState}
                />
              </div>
            }
            {cliente.fechaContratoSocial &&
              <div className="col-md-4 form-group">
                <label>Alta de Contrato Social *</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Alta de Contrato Social"
                  name="fechaAlta"
                  value={moment(cliente?.fechaContratoSocial)
                    .utcOffset(180)
                    .format("YYYY-MM-DD")}
                  disabled={checkDisabledByRol()}
                  onChange={actualizarState}
                />
              </div>
            }
          </div>
        </>
      }
      {(cliente.nombre || cliente.apellido) &&
        <>
          <SelectEstadoCivil actualizarState={actualizarState} cliente={cliente} />
          <SelectNupcias cliente={cliente} actualizarState={actualizarState} />
          <DatosConyuge cliente={cliente} actualizarState={actualizarStateV2} />
        </>
      }
      <div className="row">
        <div className="text-center col-md-6 form-group">
          <SelectInmobiliaria cliente={cliente} actualizarState={actualizarState} />
        </div>
        {!isNew &&
          <div className="text-center col-md-6 form-group">
            <label>Activo</label>
            <input
              type="checkbox"
              className="form-control"
              name="activo"
              checked={cliente?.activo}
              onChange={checkActivo}
            />
          </div>
        }
      </div>
    </>
  );
};
